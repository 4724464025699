import {useEffect} from 'react'
import Paragraph from "./Paragraph"
import Options from './Options';
import { useTranslation, Trans } from 'react-i18next';
import SearchInfo from './SearchInfo.js';
import MatchedParagraphs from './MatchedParagraphs.js';
import MatchedTitles from './MatchedTitles.js';
import SearchKey from './SearchKey.js';
import MatchedTopics from './MatchedTopics.js';
import SearchInfoGoTo from './SearchInfoGoTo.js';
import { get_full_paragraphs_list } from '../utils.js';

function SearchPage({data,translateAll, setTranslateAll, pagenum, setPagenum, topicView, setTopicView, searchText, setSearchText, searchIn, setSearchIn, view, setView, langAppState, setLangAppState}) {
  const { t, i18n } = useTranslation();

// el awel e3ml b rakam wa7ed
// separate the search text by commas or spaces
// put the number in a list
// render by mapping to jsx

function isNumber(char) {
  return /^\d$/.test(char);
}

function pnums_parser(input){
  // #todo support ranges
  console.log("input is %s: ", input)
  const searchCharacters = input.split("") // array of charcters
  let num_list =  []
  let current_snum = ""
  for(let i = 0; i < searchCharacters.length; i++){
    if (isNumber(searchCharacters[i]) || searchCharacters[i] == "-"){
      current_snum += searchCharacters[i]
    }else if (current_snum){
      num_list.push(current_snum)
      current_snum = ""
    }else {continue}
  }

  // at last add last number if there is a number current snum
  if (current_snum){
    num_list.push(current_snum)
  }

  console.log("output is %s: ", num_list)
  return(num_list)
}

//let pnum_regex = /^[^0-9]*([0-9]{1,4}[^0-9]*)*$/
let pnum_regex = /^[\s,،.\/\\]*([0-9]{1,4}[\s,،.\-\/\\]*)*$/
let page = <></>

if(searchText === "") {
  // page = <SearchInfo/>
  page = <div>
            <div className='mt-4 text-sm'>&#x2022;
              {t("search_intro1")}
            </div>
            <div className='mt-2 text-sm'>&#x2022;
              {t("search_intro2")}
            </div>
            <div className='mt-2 text-sm'>&#x2022;
              {t("search_intro3")}
            </div>
            <SearchInfoGoTo setSearchText={setSearchText}/>
        </div>

} else if (searchText === "?" || searchText === "؟"){
      page = <SearchInfo/>

}else if (pnum_regex.test(searchText)){
  // paragraph number parser
  let num_list = get_full_paragraphs_list(pnums_parser(searchText))
  page = 
  <div className="my-6 border-2 shadow-md rounded-sm bg-mysearchcardbg">
  <div className='text-myblue text-lg mx-4 my-1'>
    {num_list.length == 1? <>{t("paragraph_no")} {num_list}</>: <>{t("paragraphs_no")} {num_list.join(", ")}</>}
    </div>
  {num_list.map((pnum) => {
    if (pnum > 2856 || pnum < 1) {
      return (
        <div className='mb-4 mx-4 bg-background text-myred py-2 px-3 rounded-md border shadow-md my-4'>
          <span>{t("wrong_paragraph")}</span>
          <span className='bg-yellow-200'>{pnum}</span>
          <span>{t("why_wrong_paragraph")}</span>
      </div>
      )
    }
   return (
      <div className='mb-4 mx-4 bg-background'>
        <Paragraph translateAll={translateAll} pnum={pnum} data={data} card="both"></Paragraph>
      </div>
  )})
  }
  </div>

} else {
    page = <div id="search_results" className='my-4'>
          {/* <SearchKey searchIn={searchIn} setSearchIn={setSearchIn} ></SearchKey> */}
    {/* {searchIn==="topics"? <MatchedTopics data={data} searchText={searchText} topicView={topicView} setTopicView={setTopicView} view={view} setView={setView} setLangAppState={setLangAppState}></MatchedTopics>: <></>}
    {searchIn==="titles"? <MatchedTitles pagenum={pagenum} setPagenum={setPagenum} data={data} searchText={searchText} setLangAppState={setLangAppState}></MatchedTitles>: <></> }
    {searchIn==="paragraphs"? <MatchedParagraphs translateAll={translateAll} setTranslateAll={setTranslateAll} data={data} searchText={searchText} setLangAppState={setLangAppState}></MatchedParagraphs>: <></>} */}
      <MatchedTopics data={data} searchText={searchText} topicView={topicView} setTopicView={setTopicView} view={view} setView={setView} setLangAppState={setLangAppState}></MatchedTopics>
      <MatchedTitles pagenum={pagenum} setPagenum={setPagenum} data={data} searchText={searchText} setLangAppState={setLangAppState}></MatchedTitles>
      <MatchedParagraphs translateAll={translateAll} setTranslateAll={setTranslateAll} data={data} searchText={searchText} setLangAppState={setLangAppState}></MatchedParagraphs>

          </div>
}

// search card action
// 1) read the catechism at this paragraph
// 2) احصل على لينك مستقل لهذه الفقرة لمشاركتها

  return (
    <div className='mb-8 px-4'>
      {page}
    </div>
    
  )
}

export default SearchPage
