export function howArabic(str) {
    // strip punctuation, digits, and spaces
    str = str.replace(/[\u0021-\u0040\s.،”“,:\"]/g, "");

    const match = str.match(/[\u0621-\u0652]/g) || [];
    const result = match.length / str.length;

    return result;
}

export function ArabicRegex(searchText) {
    let trimmed = searchText.trim()

    if (trimmed.startsWith("ال")){
        console.log(trimmed)
        trimmed = trimmed.slice(2)
        console.log(trimmed)
    }

    let result = ""
    Array.from(trimmed).forEach(char => {
        if (/[ًٌٍَُِّْـ]/.test(char)) return
        result = result + char + "[ًٌٍَُِّْـ]?" 
    });

    result = result.replace(/[أإآا][?!ل]/g, "[أ|إ|آ|ا][ًٌٍَُِّْـ]?")
    result = result.replace(/[ةه]/g, "[ة|ه][ًٌٍَُِّْـ]?")
    result = result.replace(/[يى]/g, "[ي|ى][ًٌٍَُِّْـ]?")
    result = "\\s[ا\\s؛و““\"،\(\!\-]*(ال)?" + result + "[ا\\s؟.؛،,”“\":\)\!\-]*\\s"
    return result
}

export function EnglishRegex(searchText) {
    let trimmed = searchText.trim(" ")
    return "\\s[\\s““\"،\(\!\-]*" + trimmed + "[\\s.،,”“\":\)\!\-]*\\s"
}

// export default {ArabicRegex, howArabic}