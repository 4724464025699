import LangButton from "./LangButton"
import { useTranslation, Trans } from 'react-i18next';

function LangButtons({langAppState, setLangAppState}) {
  const { t, i18n } = useTranslation();

  return (
    <div dir="rtl" className='flex flex-row gap-2 bg-mylangbg shadow-sm rounded-sm'>
      <LangButton lang="ar" selected={langAppState} onClick={() => setLangAppState("ar")}>english</LangButton>
      <LangButton lang="en" selected={langAppState} onClick={() => setLangAppState("en")}>arabic</LangButton>
    </div>
      )
}

export default LangButtons
