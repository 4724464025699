import { useTranslation, Trans } from 'react-i18next';
import {Link } from "react-router-dom";

function BiblePageButtons({bibleView, setBibleView}){
    const { t, i18n } = useTranslation();

    const selectedClassname = "cursor-pointer text-center bg-mybuttonselected text-white border-b-4 border-b-mycomplementary px-2 pt-1 pb-1 shadow-sm"
    const notSelectedClassName = "border-b border-b-mycomplementary cursor-pointer text-center bg-mybuttonbg px-2 pt-1 pb-1  shadow-sm"
    
    let booksButtonClassname, dailyButtonClassname;
    
    if (bibleView === "books"){
        booksButtonClassname = selectedClassname + " border-e border-e-background rounded-ts-md "
        // topicsButtonClassname = notSelectedClassName + " border-x border-x-background "
        dailyButtonClassname = notSelectedClassName + " rounded-te-md "
    } else if (bibleView === "daily"){
        booksButtonClassname = notSelectedClassName + " border-e border-e-background  rounded-ts-md "
        dailyButtonClassname = selectedClassname + " rounded-te-md "
        // dailyButtonClassname = notSelectedClassName +  " border-s border--background rounded-te-md "
    } else {
        booksButtonClassname = notSelectedClassName + " rounded-ts-md "
        // topicsButtonClassname = notSelectedClassName +" border-x border-x-background "
        dailyButtonClassname = notSelectedClassName +  " border-s border--background rounded-te-md  "
    }

    function handleBooksClick(){
        if (bibleView !== "books"){
            setBibleView("books")
        }
    }
    function handleDailyClick(){
        if (bibleView !== "daily"){
            setBibleView("daily")
        }
    }

    return(
        <div className="flex w-full">
            <div className="w-full border-b border-b-mycomplementary"></div>
            <div className="flex"> 

                <div className={booksButtonClassname} onClick={handleBooksClick}>
                    {t("books")} 
                </div>

                <div className={dailyButtonClassname + "w-32"} onClick={handleDailyClick}>
                {t("daily")} 
                </div>
                
            </div>
            <div className="w-full border-b border-b-mycomplementary"></div>
        </div>
    )   
}

export default BiblePageButtons
