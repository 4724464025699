import React, { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next';

function MetaInfo() {
    const { t, i18n } = useTranslation();
    useEffect( () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    } ,[])
    if (i18n.resolvedLanguage === "ar"){
        return (
            <div className='mx-4'>
                <div className='text-2xl mt-6 mb-2'>المصادر</div>
                    <div className="mb-2">&#x2022; بمراجعة الأب بولس ساتي، راعي بازيليك سانت فاتيما </div>
                    <div className='mb-2'>&#x2022;
                        ترجمتان الكاتيكيزم <a target="_blank" href="https://www.vatican.va/archive/catechism_ar/index_ar.htm" className='clickable text-primary'> العربية </a>  و<a target="_blank" href="https://www.vatican.va/archive/ENG0015/_INDEX.HTM" className='clickable text-primary'> الانجليزية </a> المستخدمتان هنا هما النسختان المقدمتان على موقع <a target="_blank" href="https://www.vatican.va/" className='clickable text-primary'> الفاتيكان</a>.
                    </div>
                    <div>&#x2022;
                    ترجم النسخة العربية للكاتيكيزم، عن الطبعة اللاتينيّة الأصليّة، المتروبوليت حبيب باشـا، المطران يوحنّا منصور، المطران كيرلّس سليم بسترس، الأب حنّـا الفاخـوري ونقّحه قسم اللغة العربية التابع لأمانة سرّ الكرسي الرسولي.
                    </div>  
                    <div className='mt-2 mb-2'>&#x2022;
                           الترجمة اليسوعية للكتاب المقدس المستخدمة هنا هي <a target="_blank" href="https://arabiccatholicbible.com/" className='clickable text-primary'> النسخة المقدمة من دار المشرق </a>
                    </div>

                <div className='text-2xl mt-6 mb-2'>المشاركة</div>
				<div className="italic font-bold">اذا أدرت أن تشارك أي صفحة تراها، فقط انسخ الرابط و ارسله</div> 
دائما ستجد حالة البرنامج محفوظة في الرابط (اللغة، صفحة القراءة، الموضوع، نتائج البحث... الخ) لذلك اذا أردت أن تشارك أي صفحة ما عليك سوى أن تنسخ الرابط و ترسله لمن تريد مشاركته بأي وسيلة. أيضًا يمكنك حفظ حالة البرنامج لنفسك بحفظ الرابط للعودة اليه في وقت لاحق لاستكمال قراءة ما توقفت عنده
					
                {/* <div className='text-2xl mt-6 mb-2'>تنويهات عن الترجمة العربية</div>
                ستجد هذا التنويه في صفحة النسخة العربية على موقع الفاتيكان : <span className='italic'>"يرجى الأخذ بعين الاعتبار أن هذه النسخة غير نهائية"</span>، لذلك قد تجد بعض الأخطاء الإملائية في نصوص الفقرات، ولكن أغلبها غير مؤثر على القراءة الى حد كبير. وبشأن تلك الأخطاء الطفيفة، الأفضل لنا أن ننتظر إتمام تنقيح النسخة العربية حتى لا يكون سوى نسخة واحدة ومصدر واحد للترجمة العربية "الحقيقية" وتظل النسخة المستخدمة هنا مجرد انعكاس لها  */}
                {/* <div className='text-lg  mt-6 mb-2'>معلومات للمبرمجين</div>
                <div className='mb-1 text-sm'>
                    كل أكواد البرنامج هي مفتوحة المصدر و يمكنك أن تجدها على Github من  <a target="_blank" href="https://github.com/saadjsct/saadjsct.github.io" className="clickable text-primary"> هنا</a> و من <a target="_blank" href="https://github.com/saadjsct/ccc-json" className='clickable text-primary'>هنا</a>
                </div> */}
                {/* <div className="text-lg mt-3 mb-2"> الرخصة لاستخدام كود البرنامج</div>
                <div className='mb-1 text-sm'>
                    كل الأكواد هي تحت رخصة <a target="_blank" href="https://creativecommons.org/licenses/by-nc/4.0/deed" className='clickable text-primary'>Creative Commons NonCommercial license</a>. وذلك يجعل الكود حر من ملكية أحد ما، مما يعني أن لك كل الحرية أن تحمّله أو تقتبس منه أو تعدّله لكي تستخدمه الاستخدام الذي تراه مناسبًا، على أن يكون ذلك غير هادف للربح بأي شكل من الأشكال. الشرط الآخر الذي تضعه هذه الرخصة هو أن تجعل تعديلك أيضًا تحت نفس الرخصة أو رخصة متوافقة معها، أي أن غيرك أيضًا له نفس ذات الحقوق نحو تعديلك أو تطبيقك المعتمد على هذه الأكواد
                </div>
                <div className='mb-1 text-sm'>
                    لكن بالطبع هذه الرخصة تنطبق فقط على الكود وليس على النصوص المستخدمة، أي لا يمكنك تعديل النص أو طباعته وبيعه الا بإذن من له الحقوق 
                </div> */}


                {/* <div className='text-2xl  mt-6 mb-2'>معلومات للمبرمجين</div>
                <div className=''>
    <div className='mb-1'>
هذا البرنامج هو في الحقيقة عبارة عن برنامجين منفصلين، الأول هو تحويل الكاتيكزم العربي والانجليزي لهيكل بيانات بصيغة الJSON، وهي صيغة تفهمها كل لغات البرمجة وتدعمها. كل ملفات الJSON مفتوحة المصدر ويمكنك أن تجدها على Github <a target="_blank" href="https://github.com/saadjsct/ccc-json" className='clickable text-primary'> من هنا</a>، أيضا المصادر والأكواد (In&nbsp;Python) التي تحلل تلك المصادر وتبني تلك الملفات ستجدها على نفس الرابط 
    </div>
    <div className='mb-1'>
الجزء الثاني، هذا الWebapp، ما هو الا تطبيق للجزء الأول، هو يبدأ من ما يتيحه وجود تمثيل كامل لهيكل الكاتيكيزم بصيغة الJSON لعمل بعض العلاقات بين مكونات الكاتيكزم لتسهيل قرائته وتفتيشه ودراسته والرجوع اليه. 
    </div>
<div>
تم بناء هذا البرنامج باستخدام React وأيضًا كل الملفات والأكواد الخاصة بالموقع ستجدها على Github <a target="_blank" href="https://github.com/saadjsct/saadjsct.github.io" className="clickable text-primary">من هنا</a>. 
</div>
	<div className="text-lg mt-3 mb-2"> الرخصة لاستخدام كود البرنامج</div>
    <div className='mb-1'>
كل الأكواد هي تحت رخصة <a target="_blank" href="https://creativecommons.org/licenses/by-nc/4.0/deed" className='clickable text-primary'>Creative Commons NonCommercial license</a>. وذلك يجعل الكود حر من ملكية أحد ما، مما يعني أن لك كل الحرية أن تحمّله أو تقتبس منه أو تعدّله لكي تستخدمه الاستخدام الذي تراه مناسبًا، على أن يكون ذلك غير هادف للربح بأي شكل من الأشكال. الشرط الآخر الذي تضعه هذه الرخصة هو أن تجعل تعديلك أيضًا تحت نفس الرخصة أو رخصة متوافقة معها، أي أن غيرك أيضًا له نفس ذات الحقوق نحو تعديلك أو تطبيقك المعتمد على هذه الأكواد
    </div>
    <div className='mb-1'>
لكن بالطبع هذه الرخصة تنطبق فقط على الكود وليس على نصوص الكاتيكزم، كل النصوص المستخدمة حقوقها محفوظة بالكامل للكرسي الرسولي، أي لا يمكنك تعديل النص أو طباعته وبيعه الا بإذن من له الحقوق 
    </div>
                </div> */}
            </div>
          )
    } else {
        return (
            <div className='mx-4'>
            <div className='text-2xl mt-6 mb-2'>Acknowledgement</div>
                <div className="mb-2">&#x2022; under the supervision and revision of Father. Paulus Sati, Bishop of Saint Fatima Basilica</div>
                <div className='mb-2'>&#x2022;
                    The <a target="_blank" href="https://www.vatican.va/archive/catechism_ar/index_ar.htm" className='clickable text-primary'>Arabic</a> and <a target="_blank" href="https://www.vatican.va/archive/ENG0015/_INDEX.HTM" className='clickable text-primary'>English</a> Translations of the Catechism used here are the versions presented on the <a target="_blank" href="https://www.vatican.va/" className='clickable text-primary'>Vatican website</a>
                </div>
                <div>&#x2022;
                    Translated the Arabic version, from the original Latin Version, Metropolitant Habib Pacha, Archbishop Youhanna Mansour, Archbishop Cyril Salim Bustros, Father Hanna Fakhourie, and revised by the Secretary of the Holy See's department of Arabic language
                </div>
                <div className='mt-2 mb-2'>&#x2022;
                    The Jesuit translation of the Bible used here is <a target="_blank" href="https://arabiccatholicbible.com/" className='clickable text-primary'> the version presented by  Dar el-Mashreq </a>
                </div>       

            <div className='text-2xl mt-6 mb-2'>Sharing</div>
			<div className="italic font-bold">If you want to share any page you see, just copy the URL and send it</div> 
			You will always find the state of the program perserved in the URL (The language, reading page, topic, search results... etc). so if you want to share any page you can simply copy the URL(page link) and send it to whom you want to share it with. Also, you can save the state of the program for yourself by saving the URL, so that you can visit it later to continue from where you left off

            {/* <div className='text-2xl mt-6 mb-2'>Notices on the Arabic Translation</div>
<div> "Please note that this version is not final", you will find this note written in the Vatican website about the Arabic translation. That's why you may find some spelling mistakes in the text - but most of them don't affect comprehension in any way nor hinder the reading experience too much. And regarding those minor mistakes, it is better to wait for a finalized version so that there may be one "True" revised Arabic version, the one on the Vatican website. </div>             */}
            {/* <div className='text-lg  mt-6 mb-2'>Information For Developers</div>
                <div className='mb-1 text-sm'>
                 All the code and sources are open-source and you can find them on Github from <a target="_blank" href="https://github.com/saadjsct/saadjsct.github.io" className="clickable text-primary"> here</a> and from <a target="_blank" href="https://github.com/saadjsct/ccc-json" className='clickable text-primary'>here</a>
                </div> */}
            {/* <div className=''>
<div className='mb-1'>
Under the hood, this project consists of two smaller projects (you can call them a backend and a frontend). the first component (the backend) deals with representing the Catechism in a JSON data structure, which is a format that can be understood by all programming languages. All the JSON files are open source, and you can find them on Github from <a target="_blank" href="https://github.com/saadjsct/ccc-json" className='clickable text-primary'>here</a>.                          
</div>
<div className='mb-1'>
The second smaller component (the frontend) is this webapp, which is just an application on the first part. It starts from what is possible having a full data structure of the catechism and uses the JSON to make some relations between the components of the Catechism to facilitate reading, studying, searching, and referencing the Catechism. 
</div>
<div>
This webapp was built using React. And also you can find all the files and sources on Github from <a target="_blank" href="https://github.com/saadjsct/saadjsct.github.io" className="clickable text-primary">here</a>
</div>
<div className="text-lg mt-3 mb-2">The License For the Code</div>
<div className='mb-1'>
The code is Licensed under <a target="_blank" href="https://creativecommons.org/licenses/by-nc/4.0/deed" className='clickable text-primary'>Creative Commons NonCommercial license</a>. which makes the code free from the ownership of anyone. Meaning that you have all the rights to download, copy, modify, distribute, or use it in any way you may find suitable, as long as it is for non-profit use. the other restriction that this license put is that you have to distribute your modification or copy under the same license (or a compatible one), which means that others also has the same rights towards your copy.
</div>
<div className='mb-1'>
but definitely this license applies only on the code of the program, not on the texts of the Catechism. all the text used has all the rights reserved for the Holy See, which means that you can't modify or sell it without the permission of the copyright owners  
</div>
            </div> */}
        </div>
      )
    }
}

export default MetaInfo