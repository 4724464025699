
function LangButton({lang, selected, onClick}) {

    const isChecked = (lang===selected)

    return (
        <div className=" px-2 py-1 cursor-pointer flex flex-row justify-start items-center content-center"
             onClick={onClick}
        >
            <span className="ml-1">
                {(lang==="ar")? "عربي": "English"}
            </span>
            <input
                type="radio"
                className="m-0 size-4 accent-mycomplementary"
                checked={isChecked}
            />
        </div>
    )
}

export default LangButton