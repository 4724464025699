import { useEffect, useRef, useState } from 'react';

export function useDidUpdateEffect(fn, inputs) {
    const isMountingRef = useRef(false);
  
    useEffect(() => {
      isMountingRef.current = true;
    }, []);
  
    useEffect(() => {
      if (!isMountingRef.current) {
        return fn();
      } else {
        isMountingRef.current = false;
      }
    }, inputs);
  }
  
  export function get_full_paragraphs_list(paragraphs){
    let full_paragraphs_list = []
    for (let i = 0; i < paragraphs.length; i++){
        const hyphen_index = paragraphs[i].indexOf("-")
        if ( hyphen_index === -1){
            full_paragraphs_list.push(paragraphs[i])
        } else {
            const left_of_hyphen = paragraphs[i].substring(0, hyphen_index)
            const right_of_hyphen = paragraphs[i].substring(hyphen_index+1)
            let first_in_range_string, diff_ceiling;
            if (left_of_hyphen.length === right_of_hyphen.length){
                if (Number(left_of_hyphen) < Number(right_of_hyphen)){
                    first_in_range_string = left_of_hyphen
                    diff_ceiling = Number(right_of_hyphen)
                } else {
                    first_in_range_string = right_of_hyphen
                    diff_ceiling = Number(left_of_hyphen)
                }
            } else {
                if (left_of_hyphen.length > right_of_hyphen.length){ 
                    // ella fe 7alat mo3yna dyman elly tolo akbar howwa el asghar
                    // el 7alat de msln 99-110 lw fe jump fel digits dol ana ma2darsh atwake3 8ayarhom manually l 099-110 msln
                    first_in_range_string = left_of_hyphen
                    diff_ceiling = Number(right_of_hyphen)

                } else {
                    first_in_range_string = right_of_hyphen
                    diff_ceiling = Number(left_of_hyphen)
                }
            }
            // first_in_range_string = paragraphs[i].substring(0, hyphen_index)
            // diff_ceiling = Number(paragraphs[i].substring(hyphen_index+1))
            let diff_floor = Number(first_in_range_string.substring(first_in_range_string.length-diff_ceiling.toString().length))

            while (diff_floor <= diff_ceiling ){ //hena bug efred el digit increases wana ba3ed
                let common_digits_string = first_in_range_string.substring(0, first_in_range_string.length - diff_floor.toString().length)
                full_paragraphs_list.push(common_digits_string + diff_floor.toString())
                diff_floor += 1
            }
        }
    }
    return full_paragraphs_list
}

export function get_topic_class(str) {
    return ""
}

export function get_parent_class(absolute_level) {

    let parent_class_name = ""

    switch(absolute_level) {
        case 1: //PART
            parent_class_name += "font-bold "
        break;
        case 2: //SECTION
            parent_class_name += " font-bold text-mybrown "      
        break;
        case 3: //CHAPTER
            parent_class_name += " font-bold text-mygreen "
        break;
        case 4: //ARTICLE
            parent_class_name += " font-bold text-myblue" 
        break;
        case 5: //PARAGRAPH
            parent_class_name += " text-myblue "
        break;
        case 6: //NUMBERED
            parent_class_name += " text-myred "
        break;
        case 7: //paragraphs 
                parent_class_name += " text-mygreen "
        break;
    }
    return parent_class_name
}

export function pagenum_string_setter(pagenum_string, key, value){
    let page_number = pagenum_string.split("@")[0]
    let scrollID = pagenum_string.split("@")[1].split("##")[0]
    let clickcount = pagenum_string.split("@")[1].split("##")[1]
    
    if (key === "pagenum"){
        page_number = value
    } else if (key === "scrollID") {
        scrollID = value
    } else if (key == "clickcount") {
        clickcount = value
    }

    return page_number + "@" + scrollID + "##" + clickcount

}

export function pagenum_string_getter(pagenum_string, key){
    let page_number = pagenum_string.split("@")[0]
    let scrollID = pagenum_string.split("@")[1].split("##")[0]
    let clickcount = pagenum_string.split("@")[1].split("##")[1]
    
    if (key === "pagenum"){
        return page_number
    } else if (key === "scrollID") {
        return scrollID
    } else if (key == "clickcount") {
        return clickcount
    }

}
export function address_getter(topicView){
    return topicView.split('/').slice(1).join("/")
}

export function address_setter(topicView, address){
    const current_letter = topicView.split('/')[0]
    return current_letter + "/" + address    
}

export function letter_getter(topicView, letter){
    return topicView.split('/')[0]

}

// export default {ArabicRegex, howArabic}