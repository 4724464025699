import {useEffect} from 'react'
import { useTranslation, Trans } from 'react-i18next';
import { useSearchParamsState } from "../useSearchParamsState"
import Markdown from 'react-markdown'
import ChapterPicker from './ChapterPicker';
import BackToBooks from './BackToBooks';
import TestamentChooser from './TestamentChooser';


function BooksPage({bible_data}) {
  const { t, i18n } = useTranslation();
  const [book, setBook] = useSearchParamsState("book", "chooser");
  const [testament, setTestament] = useSearchParamsState("tstm", "new")

  useEffect( () => {
     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  }, [book])

  let group_title_classname = " mb-1 mt-3 text-xl font-bold "
  let group1_classname = " text-lg text-myred clickable "
  let group2_classname = " text-lg text-myblue clickable "
  let group3_classname = " text-lg text-mygreen clickable "
  let group4_classname = " text-lg clickable "

  // torah -> red
  if(book === "chooser"){
    if (testament === "old"){
      return (
        <div className='mb-8 px-4 flex flex-col gap-1'>
            <TestamentChooser testament={testament} setTestament={setTestament}></TestamentChooser>

            <div className={group_title_classname + "text-myred"}>{t("torah")}</div>
            <div><span className={group1_classname}  onClick={() => setBook("gen.1")}>{t("gen")}</span></div>
            <div><span className={group1_classname} onClick={() => setBook("ex.1")}>{t("ex")}</span></div>
            <div><span className={group1_classname} onClick={() => setBook("lev.1")}>{t("lev")}</span></div>
            <div><span className={group1_classname} onClick={() => setBook("num.1")}>{t("num")}</span></div>
            <div><span className={group1_classname} onClick={() => setBook("deut.1")}>{t("deut")}</span></div>
    
            <div className={group_title_classname + " text-myblue "}>{t("history")}</div>
            <div><span className={group2_classname} onClick={() => setBook("josh.1")}>{t("josh")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("judg.1")}>{t("judg")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("ruth.1")}>{t("ruth")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("1sam.1")}>{t("1sam")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("2sam.1")}>{t("2sam")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("1kings.1")}>{t("1kings")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("2kings.1")}>{t("2kings")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("1chron.1")}>{t("1chron")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("2chron.1")}>{t("2chron")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("ezra.1")}>{t("ezra")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("neh.1")}>{t("neh")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("tob.1")}>{t("tob")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("jth.1")}>{t("jth")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("est.1")}>{t("est")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("1macc.1")}>{t("1macc")}</span></div>
            <div><span className={group2_classname} onClick={() => setBook("2macc.1")}>{t("2macc")}</span></div>
    
            <div className={group_title_classname + " text-mygreen "}>{t("wisdom")}</div>
            <div><span className={group3_classname} onClick={() => setBook("job.1")}>{t("job")}</span></div>
            <div><span className={group3_classname} onClick={() => setBook("ps.1")}>{t("ps")}</span></div>
            <div><span className={group3_classname} onClick={() => setBook("prov.1")}>{t("prov")}</span></div>
            <div><span className={group3_classname} onClick={() => setBook("eccles.1")}>{t("eccles")}</span></div>
            <div><span className={group3_classname} onClick={() => setBook("song.1")}>{t("song")}</span></div>
            <div><span className={group3_classname} onClick={() => setBook("wisdofsol.1")}>{t("wisdofsol")}</span></div>
            <div><span className={group3_classname} onClick={() => setBook("sir.1")}>{t("sir")}</span></div>
    
            <div className={group_title_classname }>{t("prophets")}</div>
            <div><span className={group4_classname} onClick={() => setBook("isa.1")}>{t("isa")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("jer.1")}>{t("jer")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("lam.1")}>{t("lam")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("bar.1")}>{t("bar")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("ezek.1")}>{t("ezek")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("dan.1")}>{t("dan")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("hos.1")}>{t("hos")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("joel.1")}>{t("joel")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("amos.1")}>{t("amos")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("obad.1")}>{t("obad")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("jonah.1")}>{t("jonah")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("mic.1")}>{t("mic")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("nah.1")}>{t("nah")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("hab.1")}>{t("hab")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("zeph.1")}>{t("zeph")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("hag.1")}>{t("hag")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("zech-2.1")}>{t("zech-2")}</span></div>
            <div><span className={group4_classname} onClick={() => setBook("mal.1")}>{t("mal")}</span></div>
    
          </div>
            ) } else {
              return (
                <div className='mb-8 px-4 flex flex-col gap-1'>
                <TestamentChooser testament={testament} setTestament={setTestament}></TestamentChooser>

                <div className={group_title_classname + "text-myred"}>{t("gospels")}</div>
                <div><span className={group1_classname} onClick={() => setBook("matt.1")}>{t("matt")}</span></div>
                <div><span className={group1_classname} onClick={() => setBook("mac.1")}>{t("mac")}</span></div>
                <div><span className={group1_classname} onClick={() => setBook("luke.1")}>{t("luke")}</span></div>
                <div><span className={group1_classname} onClick={() => setBook("john.1")}>{t("john")}</span></div>
        
                <div className='mt-4'><span className={group1_classname } onClick={() => setBook("acts.1")}>{t("acts")}</span></div>
        
                <div className={group_title_classname + "text-myblue"}>{t("pauls_letters")}</div>
                <div><span className={group2_classname} onClick={() => setBook("rom.1")}>{t("rom")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("1cor.1")}>{t("1cor")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("2cor.1")}>{t("2cor")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("gal.1")}>{t("gal")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("eph.1")}>{t("eph")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("phil.1")}>{t("phil")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("col.1")}>{t("col")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("1thess.1")}>{t("1thess")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("2thess.1")}>{t("2thess")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("1tim.1")}>{t("1tim")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("2tim.1")}>{t("2tim")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("titus.1")}>{t("titus")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("philem.1")}>{t("philem")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("heb.1")}>{t("heb")}</span></div>
        
                <div className={group_title_classname + "text-myblue"}>{t("general_letters")}</div>
                <div><span className={group2_classname} onClick={() => setBook("james.1")}>{t("james")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("1pet.1")}>{t("1pet")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("2pet.1")}>{t("2pet")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("1john.1")}>{t("1john")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("2john.1")}>{t("2john")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("3john.1")}>{t("3john")}</span></div>
                <div><span className={group2_classname} onClick={() => setBook("jude.1")}>{t("jude")}</span></div>
        
                <div className='mt-4'><span className={group4_classname} onClick={() => setBook("rev.1")}>{t("rev")}</span></div>
                </div>

    
              )
            }
} else {
  let bookname = book.split(".")[0]
  let chapter = book.split(".")[1]
  console.log(bible_data.arabic[bookname][chapter - 1])
    return (
      <div className='mb-8 px-4'>
      <BackToBooks setBook={setBook}></BackToBooks>
      <div className="font-bold text-xl mt-4 mb-4 text-center">{t(bookname)}</div>
      <ChapterPicker bible_data={bible_data} book={book} setBook={setBook}></ChapterPicker>
      <div className='markdown my-6'>
      {/* {
        bible_data.arabic[bookname].map((chapter) => {
          return <><hr/><Markdown>{chapter}</Markdown></>
        })
      } */}
      <Markdown>{bible_data.arabic[bookname][chapter - 1]}</Markdown>
      </div>

      <ChapterPicker bible_data={bible_data} book={book} setBook={setBook}></ChapterPicker>
      </div>
    )
  }
}

export default BooksPage
