import {useEffect, useState} from 'react'
import { useTranslation, Trans } from 'react-i18next';
import { useSearchParamsState } from "../useSearchParamsState"
import Datetime from 'react-datetime';
import moment from 'moment';
import ReadingCard from './ReadingCard';
import { from_gregorian_to_liturgy } from '../liturgy-tools';
// import Select from "react-dropdown-select";


function DailyPage({bible_data}) {
  const { t, i18n } = useTranslation();

  const [date, setDate] = useSearchParamsState("date", moment().format("D/M/YYYY"))
  
  let liturgy_date = from_gregorian_to_liturgy(date)
  let day = liturgy_date.split("/")[0]
  let week = liturgy_date.split("/")[1]
  let mosem = liturgy_date.split("/")[2]

  let readings = bible_data["daily"][mosem][week][day]
  let momdate = moment(date, "D/M/YYYY")

  let specials = {
    "immaculate": ["song 6: 10; 7: 2 ; 7: 6 ; 7: 8", "heb 1: 1-4", "john 1: 1-14"],
    "deneh": ["num 24: 2-9", "isa 4: 2-5 ; isa 11: 1-5 ; isa 12: 4-5", "titus 2: 11-14", "matt 3: 1-12"],
    "1st-ba3otha": ["isa 63: 17-19 ; 64: 1-12", "hab 3: 1-19", "1tim 2: 1-15 ; 1tim 3: 1-10", "matt 18: 23-35"],
    "2nd-ba3otha": ["1sam 7: 3-9", "isa 59: 1-18", "rom 12: 1-21", "luke 18: 2-14"],
    "3rd-ba3otha": ["joel 2: 15-27", "jonah 3: 1-10 ; jonah 4: 1-11", "rom 9: 14-33 ; rom 10: 1-17", "matt 6: 1-18"],
    "mawta-mo2mnen": ["ezek 37: 1-7", "2cor 15: 54-58", "matt 25: 31-40"],
    "gom3et-elshohadaa": ["dan 3: 24-45", "acts 6: 8-15 ; acts 7: 54-50", "heb 11: 1-6", "matt 10: 16-23" ],
    "1st-sun-melad": ["gen 21: 1-8", "1sam 1: 19-28", "gal 4: 18-22 ; gal 5: 1", "matt 2: 1-12"],
    "2nd-sun-melad": ["ex 2: 1-10", "isa 49: 1-5", "2tim 2: 1-11", "luke 2: 22-38"],
    "3ed-elgasad": ["gen 14: 8-20", "mal 1: 6-11", "1cor 10: 15-17 ; 1cor 11: 23-30", "john 6: 53-64"],
    "3ed-eltagally": ["isa 6: 6-13", "acts 1: 21-26", "heb 12: 18-25", "matt 17: 1-8"],
    "3ed-entkal": ["ex 15: 11-20", "acts 1: 12-14", "rom 16: 1-6 ; 16: 16", "matt 12: 46-50"],
    "3ed-elsalib": ["isa 52: 1-6", "acts 2: 22-28", "phil 2: 5-11", "luke 24: 13-35"],
  }

  // hena han3ml kol el cases, nktb elly 3ayzen nektbo w ngeb el readings mn makan ma7na 3ayzen
  // el a7ad el 3adeya hat50sh 3ala file el readings, w kol reading msh mawgoda fel json da hatb2alha 7ala 5assa hena
  // Sun/first/keyama = 3ed keyama
  // 31-

  let eid_classname = " font-bold text-myred text-lg mt-1 mb-3"
  let title;

  if (liturgy_date.split("/").length > 3) {
    let ba3otha = liturgy_date.split("/")[3]
    title = <>
    <div><span>{t("mowafek")}</span> <span className='font-bold'>{t(day)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(week)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(mosem)}</span></div>
    <div className={eid_classname}>{t(ba3otha)}</div>
  </>
    readings = specials[ba3otha]


  }
  else if (momdate.format("D/M") === "6/1"){
    title = <div className={eid_classname}>{t("3ed-deneh")}</div>
    readings = specials["deneh"]
  }
   else if (momdate.format("D/M") === "6/8"){
    title = <div className={eid_classname}>{t("3ed-eltagally")}</div>
    readings = specials["3ed-eltagally"]
  }
   else if (momdate.format("D/M") === "15/8"){
    title = <div className={eid_classname}>{t("3ed-entkal")}</div>
    readings = specials["3ed-entkal"]
  }
   else if (momdate.format("D/M") === "14/9"){
      title = <div className={eid_classname}>{t("3ed-elsalib")}</div>
      readings = specials["3ed-elsalib"]
  }
   else if (liturgy_date === "Sun/seventh/som") {
    title = <div className={eid_classname}>{t("sa3aneen")}</div>
  }
   else if (liturgy_date === "Thu/seventh/som") {
    title = <div className={eid_classname}>{t("5ames-el3ahd")}</div>
  }
   else if (liturgy_date === "Fri/seventh/som") {
    title = <div className={" font-bold text-xl py-2 "}>{t("elgom3a-el3azema")}</div>
  }
   else if (liturgy_date === "Sat/seventh/som") {
    title = <div className={eid_classname}>{t("sabt-elnoor")}</div>
  }
   else if (liturgy_date === "Sun/first/keyama") {
    title = <div className={eid_classname}>{t("3ed-keyama")}</div>
  }
   else if (liturgy_date === "Sun/second/keyama") {
    title = <>
    <div><span>{t("mowafek")}</span> <span className='font-bold'>{t(day)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(week)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(mosem)}</span></div>
    <div className={eid_classname}>{t("new-sunday")}</div>
  </>
  }
   else if (liturgy_date === "Thu/sixth/keyama") {
    title = <div className={eid_classname}>{t("3ed-so3od")}</div>
  }
   else if (liturgy_date === "Sun/seventh/keyama") {
    title = <>
    <div><span>{t("mowafek")}</span> <span className='font-bold'>{t(day)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(week)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(mosem)}</span></div>
    <div className={eid_classname}>{t("sunday-after-so3od")}</div>
  </>
  }
   else if (liturgy_date === "Sun/first/rosol") {
    title = <div className={eid_classname}>{t("3ed-3ansara")}</div>
  }
   else if (liturgy_date === "Thu/second/rosol") {
    title = <div className={eid_classname}>{t("3ed-elgasad")}</div>
    readings = specials["3ed-elgasad"]
  }
   else if (mosem === "melad"){
        if(day === "25-12") {
          title = <div className={eid_classname}>{t("3ed-elmelad")}</div>
        } else if (day === "26-12") {
          title = <>
            <div><span className='font-bold'>{t(day)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(mosem)}</span></div>
            <div className={eid_classname}>{t("tahne2a")}</div>
          </>
        } else if (day === "1-1") {
          title = <>
          <div><span className='font-bold'>{t(day)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(mosem)}</span></div>
          <div className={eid_classname}>{t("ra2s-elsana")}</div>
        </>
        } else if ((momdate.format("ddd") === "Sun")){
            // if (momdate. || )
            if(momdate.dayOfYear() >= 3 && momdate.dayOfYear() <= 5 ){
              //second sunday
              title = <>
              <div><span className='font-bold'>{t(day)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(mosem)}</span></div>
              <div className={eid_classname}>{t("2nd-sun-melad")}</div>
            </>
            readings = specials["2nd-sun-melad"]
            } else {
              title = <>
              <div><span className='font-bold'>{t(day)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(mosem)}</span></div>
              <div className={eid_classname}>{t("1st-sun-melad")}</div>
            </>
            readings = specials["1st-sun-melad"]

            }
        }
        else {
          title = <div><span className='font-bold'>{t(day)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(mosem)}</span></div>
        }

  } else {
    title = <div><span>{t("mowafek")}</span> <span className='font-bold'>{t(day)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(week)}</span> <span>{t("from")}</span> <span className='font-bold'>{t(mosem)}</span></div>
  }

  return (
    <div className='mb-8 px-4'>
      <div className='flex flex-row gap-2 mt-6'><span>{t("readings_of")}</span>
      <Datetime inputProps={{"readonly": "true"}} closeOnSelect={true} dateFormat={"D/M/YYYY"} timeFormat={false} value={date} onChange={(m) => setDate(m.format('D/M/YYYY'))}/></div>
        <div className='mt-5'>{title}</div>
        <div className='mt-4'>
          {/* <div className='mb-3'>{t(readings.length + "readings")}</div> */}
        {readings.map((reading, index) => {
          return <ReadingCard reading={reading} order={index+1} total={readings.length} bible_data={bible_data}></ReadingCard>
        })}
        {/* <ReadingCard reading={readings[0]} order={1} total={readings.length} bible_data={bible_data}></ReadingCard> */}

        </div>
    </div>
  )
}

export default DailyPage
