import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";
import { IconContext } from "react-icons";

function Paragraph({translateAll, pnum, data, card, match_regex}) {
    const [ t, i18n ] = useTranslation();
    const [translate, setTranslate] = useState(false)
    
    translateAll = (translateAll === "true")
    let text = "";
    const isArabic = i18n.resolvedLanguage==="ar"
    const translate_p = translateAll || translate
   
    function handle_click(){
      if(!translateAll) 
      setTranslate((current_translate) => !current_translate)
    }
  
    function ExpandButton() {
      return (
        <IconContext.Provider value={{className: "inline global-class-name" }}>
          {translate? <MdExpandLess/> : <MdExpandMore/>}
        </IconContext.Provider>
      )
    }
    
  function renderArabic(){
    text = data.arabic[pnum].data

    if (match_regex){
      let matched_text = []
      text = " " + text + " "
      const regexObj = new RegExp(match_regex, "gi")
      const match_array = [...text.matchAll(regexObj)];

      let last_index = 0
      match_array.forEach((match) => {
        let stripped_match_text = match[0].trim(" ")
        matched_text.push(<>{text.substring(last_index, match.index + 1)}</>)
        matched_text.push(<span className='bg-yellow-200'>{stripped_match_text}</span>)
        last_index = match.index + 1 + stripped_match_text.length
      })
      matched_text.push(<>{text.substring(last_index)}</>)
      text = matched_text
    }

      return(
        <div dir="rtl" className=''>
          <span className = "text-myred cursor-pointer" onClick={handle_click}><ExpandButton/>{pnum}- </span>
          <span>{text}</span>
        </div>
      )
  }
  
  function renderEnglish(){
    text = data.english[pnum].data.text
    
    if (match_regex){
      let matched_text = []
      text = " " + text + " "
      const regexObj = new RegExp(match_regex, "gi")
      const match_array = [...text.matchAll(regexObj)];

      let last_index = 0
      match_array.forEach((match) => {
        let stripped_match_text = match[0].trim(" ")
        matched_text.push(<>{text.substring(last_index, match.index + 1)}</>)
        matched_text.push(<span className='bg-yellow-200'>{stripped_match_text}</span>)
        last_index = match.index + 1 + stripped_match_text.length
      })
      matched_text.push(<>{text.substring(last_index)}</>)
      text = matched_text
    }

    return(
      <div dir="ltr" className=' mb-2'>
        <span className = "text-myred cursor-pointer" onClick={handle_click}><ExpandButton/>{pnum}- </span>

        <span>{text}</span>

        {/* footnotes */}
        <div className='text-sm mt-2 font-light'>
        {data.english[pnum].data.footnotes.map((footnote) => {
          return(
            <div key={pnum.toString() + footnote.ref.toString()}
            className=''>
              <span className='text-myblue'>[{footnote.ref}]</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>{footnote.definition}</span>
            </div>
          )
        })}
        </div>

        {/* cross-reference
          #TODO add linked paragraph numbers
          the standalone links is like searching for that number
          and following links is like searching with range or with paragraphs with ","
        */}
        {data.english[pnum].data.cr.length>0 && <div className="font-light">
          <span className='text-myblue'>Cross-references </span>
          [{data.english[pnum].data.cr.join(", ")}]
        </div>}
          
      </div>
    )
  }

  function renderTranslation(){
    if (translate_p){
      if(isArabic){
        return <div className='mt-2'>{renderEnglish()}</div>
      } else {
        return <div className="mt-2">{renderArabic()}</div>
      }
    }
  }

  // add read the catechism at
  // let container_class = "mb-3 mt-2 cursor-pointer "
  let container_class = " cursor pointer my-4 "

  if(card === "both" || (translate_p && card === "translate")){
    container_class += " py-2 px-3 rounded-md border shadow-md "
  }

  return (
    <div id={pnum} key={pnum} className={container_class}>
      <div>
        {isArabic? renderArabic(): renderEnglish()}

      </div>
      <div id="translation">
          {renderTranslation()}
      </div>
    </div>

  )
}

export default Paragraph