import React from 'react'
import { IconContext } from "react-icons";
import { CiSquarePlus } from "react-icons/ci";
import { CiSquareMinus } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";

import { useTranslation, Trans } from 'react-i18next';



function TocDepthAdjuster({tocDepth, setTocDepth}) {
  const { t, i18n } = useTranslation();

  const number_tocDepth = Number(tocDepth)

  function handlePlus(){
    if (number_tocDepth < 7) setTocDepth((number_tocDepth+ 1).toString())
  }

  function handleMinus(){
    if (number_tocDepth > 1) setTocDepth((number_tocDepth - 1).toString())
  }

  let directory_depth_indicator = []
  const level = Number(tocDepth)
  if (level >= 1) directory_depth_indicator.push(<span className="text-base font-bold " onClick={() => setTocDepth("1")}>{t("parts")}/ </span>)
  if (level >= 2) directory_depth_indicator.push(<span className="text-base font-bold text-mybrown" onClick={() => setTocDepth("2")}>{t("sections")}/ </span>)
  if (level >= 3) directory_depth_indicator.push(<span className="text-base font-bold text-mygreen" onClick={() => setTocDepth("3")}>{t("chapters")}/ </span>)
  if (level >= 4) directory_depth_indicator.push(<span className="font-bold text-base text-myblue " onClick={() => setTocDepth("4")}>{t("articles")}/ </span>)
  if (level >= 5) directory_depth_indicator.push(<span className="text-myblue" onClick={() => setTocDepth("5")}>{t("paragraphs")}/ </span>)
  if (level >= 6) directory_depth_indicator.push(<span className="text-myred" onClick={() => setTocDepth("6")}>{t("numbered")}/ </span>)
  if (level === 7) directory_depth_indicator.push(<span className="text-mygreen " onClick={() => setTocDepth("7")}>{t("paragraph_groups")}/ </span>)


  return (
    <div className='text-sm mt-4'>
      <IconContext.Provider value={{className: "inline global-class-name align-center text-sm" }}>
        <IoIosInformationCircleOutline /> 
      </IconContext.Provider>    
      <span className='me-1'> {t("toc_depth")}</span>
      <span>&nbsp;</span>
      <span className='inline-flex flex-row items-baseline content-baseline gap-1'>

        <span className="cursor-pointer" onClick={handleMinus}>
          <IconContext.Provider value={{className: ("inline global-class-name size-7 " + (number_tocDepth===1? " text-mygreyedout ": " text-primary ")) }}>
            < CiSquareMinus />
          </IconContext.Provider>
        </span>
        <span className=''>{tocDepth}</span>
        <span className="cursor-pointer" onClick={handlePlus}>
          <IconContext.Provider value={{className: ("inline global-class-name size-7 " + (number_tocDepth===7? " text-mygreyedout ": " text-primary ")) }}>

            < CiSquarePlus/>
          </IconContext.Provider>
        </span>

      </span>
      <span className='ms-1 whitespace-pre-wrap'>
         {" "}
        {directory_depth_indicator}
      </span>
    </div>
  )
}

export default TocDepthAdjuster