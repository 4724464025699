import { useTranslation} from 'react-i18next';
import TopicsNav from './TopicsNav';
import Topic from './Topic';
import Paragraph from './Paragraph';
import TranslateAllCheckbox from './TranslateAllCheckbox';
import Options from './Options';
import {get_full_paragraphs_list} from "../utils"

function TopicView({topicView, setTopicView, data, translateAll, setTranslateAll, langAppState}) {
    const { t, i18n } = useTranslation();

    // fe kol el components bta3t el topic page bnt3amel bs m3 el langAppState
    
    let parent;
    let title = [];
    let index;
    let letter = topicView.slice(0,topicView.indexOf("/"))
    console.log(letter)
    const topicView_list = topicView.slice(topicView.indexOf("/") + 1, topicView.length -1).split("/")

    if (langAppState === "ar"){ 
        index = data.arabic_index
        parent = index[letter][topicView_list[0]]

    } else {
        let index = data.english_index
        parent = index[letter][topicView_list[0]]

    }
 
    // build up title
    title.push(<span className='whitespace-pre-wrap'>{t("paragraphs_related_to")}</span>)
    if (topicView_list.length >= 1){
        title.push(<><Topic onClick={() => setTopicView(letter + "/" + topicView_list[0] + "/") } topic_object={parent} level={0} with_numbers={false} with_refs={false} topicView={topicView} /><span>/ </span></>)

    } if (topicView_list.length >=2 ){
        title.push(<><Topic onClick={() => setTopicView(letter + "/" + topicView_list[0] + "/" + topicView_list[1] + "/") } topic_object={parent.children[topicView_list[1]]} level={1} with_numbers={false} with_refs={false} topicView={topicView} /><span>/ </span></>)  
   
    } if(topicView_list.length >=3 ){
        title.push(<><Topic onClick={() => setTopicView(letter + "/" + topicView_list[0] + "/" + topicView_list[1] + "/" + topicView_list[2] + "/") } topic_object={parent.children[topicView_list[1]].children[topicView_list[2]]} level={2} with_numbers={false} with_refs={false} topicView={topicView} /><span>/ </span></>)  

    } if (topicView_list.length ===4 ) {               
        title.push(<><Topic topic_object={parent.children[topicView_list[1]].children[topicView_list[2]].children[topicView_list[3]]} level={3} with_numbers={false} with_refs={false} topicView={topicView} /><span>/</span></>)  
    }

    function construct_state(parents_indices){
        topicView = letter + "/"
        for (let i = 0; i < parents_indices.length; i++){
          topicView +=  parents_indices[i]+ "/"
        }
        return topicView
      }
    

    function topic_and_paragraphs(topic_object, parents_indices, level){
        
        let content = []
        content.push(<div><Topic onClick={() => setTopicView(construct_state(parents_indices))} topic_object={topic_object} level={level} topicView={topicView} /></div>)
        if(topic_object.hasOwnProperty("paragraphs")){
            const paragraphs = get_full_paragraphs_list(topic_object["paragraphs"])
            content.push(...paragraphs.map((pnum) => {
                if (pnum <= 2865){
                    return <Paragraph translateAll={translateAll} pnum={Number(pnum)} data={data} card="translate"/>
                }
            })) 
        }
        return content
    }

    let content = []

    if (topicView_list.length === 1) { // specific to only parent
        content = topic_and_paragraphs(parent, [topicView_list[0]], 0)
        if(parent.hasOwnProperty("children")){
            for(let i = 0; i< parent.children.length; i++){
                content.push(...topic_and_paragraphs(parent["children"][i],[topicView_list[0], i], 1))

                if(parent["children"][i].hasOwnProperty("children")){
                    for(let j=0; j< parent.children[i].children.length; j++){
                        content.push(...topic_and_paragraphs(parent["children"][i]["children"][j], [topicView_list[0], i, j], 2))

                        if(parent.children[i].children[j].hasOwnProperty("children")){
                            for(let k=0; k< parent.children[i].children[j].children.length; k++){
                                content.push(...topic_and_paragraphs(parent["children"][i]["children"][j]["children"][k], [topicView_list[0], i, j, k], 3))

                            }
                        }
                    }
                }
            }
        }

    } else if (topicView_list.length === 2) { //specific to only child
        content = topic_and_paragraphs(parent.children[topicView_list[1]], [topicView_list[0], topicView_list[1]], 1)
        if (parent.children[topicView_list[1]].hasOwnProperty("children")){
            for (let i=0; i< parent.children[topicView_list[1]].children.length; i++){
                content.push(...topic_and_paragraphs(parent.children[topicView_list[1]].children[i], [topicView_list[0], topicView_list[1], i], 2))

                if(parent.children[topicView_list[1]].children[i].hasOwnProperty("children")){
                    for(let j = 0; j < parent.children[topicView_list[1]].children[i].children.length; j++){
                        content.push(...topic_and_paragraphs(parent.children[topicView_list[1]].children[i].children[j], [topicView_list[0], topicView_list[1], i, j], 3))
                    }
                }
            }
        }
    } else if (topicView_list.length === 3) { //specific to only grandchild
        content = topic_and_paragraphs(parent.children[topicView_list[1]].children[topicView_list[2]], [topicView_list[0], topicView_list[1], topicView_list[2]], 2)
        if (parent.children[topicView_list[1]].children[topicView_list[2]].hasOwnProperty("children")){
            for (let i=0; i< parent.children[topicView_list[1]].children[topicView_list[2]].children.length; i++){
                content.push(...topic_and_paragraphs(parent.children[topicView_list[1]].children[topicView_list[2]].children[i], [topicView_list[0], topicView_list[1], topicView_list[2], i], 3))
            }
        }
    } else if (topicView_list.length === 4){ //specific to only grandchild
        content = topic_and_paragraphs(parent.children[topicView_list[1]].children[topicView_list[2]].children[topicView_list[3]], [topicView_list[0], topicView_list[1], topicView_list[2], topicView_list[3]], 3)
    }


    return (
        <div>
      <Options>
          <TranslateAllCheckbox 
                  translateAll={translateAll} 
                  setTranslateAll={setTranslateAll}
              />
      </Options>
            
            <TopicsNav topicView={topicView} setTopicView={setTopicView} letter={letter}/>
            <div className='mb-4'><span>{title}</span></div>
            <div>
                {content}
            </div>
        </div>
    )
}

export default TopicView