import React from 'react'
import { useTranslation, Trans } from 'react-i18next';

function AppChooser({app, setAppState}) {
    const { t, i18n } = useTranslation();
    const selectedClassname = " cursor-pointer text-center bg-myheader border-t-4  border-e border-s border-mycomplementary px-2 pt-1 pb-1 "
    const notSelectedClassName = " cursor-pointer text-center bg-mylangbg px-2 pt-2 pb-1 border-b border-mycomplementary "
    let catButtonClassname, bibleButtonClassname;
    
    if (app === "cat"){
        catButtonClassname = selectedClassname
        bibleButtonClassname = notSelectedClassName
    } else {
        catButtonClassname = notSelectedClassName
        bibleButtonClassname = selectedClassname
    }

    function handleCatClick(){
        if (app !== "cat"){
            setAppState("cat")
        }
    }
    function handleBibleClick(){
        if (app !== "bib"){
            setAppState("bib")
        }
    }


  return (
    <div className="flex w-full mt-2">
    <div className="w-full border-b border-mycomplementary"></div>
    <div className="flex"> 


        <div className={catButtonClassname + "w-32"} onClick={handleCatClick}>
            {t("cat")}
        </div>

        <div className={bibleButtonClassname + "w-32"} onClick={handleBibleClick}>
            {t("bible")}
        </div>
        
    </div>
    <div className="w-full border-b border-mycomplementary"></div>
    </div>
)
}

export default AppChooser