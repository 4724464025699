import React from 'react'
import * as fflate from 'fflate';
import { useSearchParamsState } from "./useSearchParamsState"
import BiblePageButtons from './components/BiblePageButtons.js';
import BooksPage from './components/BooksPage.js';
import DailyPage from './components/DailyPage.js';
import MetaInfo from './components/MetaInfo';

async function decompress_json(path) {
    const compressed = new Uint8Array(
      await fetch(path).then(res => res.arrayBuffer())
    );
    const decompressed = fflate.decompressSync(compressed);
    return(decompressed);
  }
  
  async function string_decompressed (path){
    let result = await decompress_json(path)
    let utf8decoder = new TextDecoder(); // default 'utf-8' or 'utf8'
    let string = utf8decoder.decode(result)
    return(string)
  }
  
  async function parse_data(){
    let arabic_bible;
    let arabic_bible_string = await string_decompressed("/data/arabic-bible.json.gz")
    arabic_bible = await JSON.parse(arabic_bible_string)
    
    let daily;
    let daily_string = await string_decompressed("/data/daily.json.gz")
    daily = await JSON.parse(daily_string)

    return ({
      arabic: arabic_bible,
      daily: daily,
     })
  }
  
  let bible_data = await parse_data()
  
function Bible({bibleView, setBibleView}) {

// text processing needed for
// alef hamza foo2
// fel she3r el nbsp me7taga tb2a tap
// el arkam: normalize to english. add semantic charactarization
// shel el double astriks

  return (
    <div dir="rtl">
        <header className="bg-myheader shadow-sm flex flex-col items-center gap-3 ">
            <BiblePageButtons bibleView={bibleView} setBibleView={setBibleView} />
        </header>
        <div className="min-h-64 pb-8 border-b border-mycomplementary">
            {bibleView === "books" && <BooksPage bible_data={bible_data}/>}
            {bibleView === "daily" && <DailyPage bible_data={bible_data}/>}
            {bibleView === "meta_info" && <MetaInfo/>}

        </div>

    </div>
  )
}

export default Bible