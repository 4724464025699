import SearchBar from './SearchBar.js'
import PageButtons from "./PageButtons.js"

function Header({view, setView, searchText, setSearchText}) {

  return (
    <header className="bg-myheader shadow-sm flex flex-col items-center gap-4">
      <SearchBar searchText={searchText} setSearchText={setSearchText} view={view} setView={setView}/>
      <PageButtons view={view} setView={setView}/>
    </header>
  )
}
 
export default Header