import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowUp} from "react-icons/md";
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";


function TopicsNav({topicView, setTopicView, letter}) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    function handle_back() {
        navigate(-1)
    }

    function handle_gotoindex() {
        setTopicView(letter + "/")
    }


    let classname_continer = "flex flex-row justify-end my-3 "
    
    return (
        <div className="flex mb-2 justify-between">
        <div className={classname_continer}>
            <button className="clickable me-7 flex items-center text-primary " onClick={handle_back}>
                 { i18n.resolvedLanguage==="ar"? <MdKeyboardArrowRight />: <MdKeyboardArrowLeft />}
                 <span>{t("back")}</span>
            </button>
            <button className="clickable me-7 flex items-center text-primary " onClick={handle_gotoindex}>
                <MdKeyboardArrowUp /> <span>{t("topics_toc")}</span>
            </button>
        </div>
        </div>
    
        // <div>
        //     <button className=" my-3 flex items-center text-primary clickable" onClick={handle_click}>
        //         { i18n.resolvedLanguage==="ar"? <MdKeyboardArrowRight />: <MdKeyboardArrowLeft />}
        //         <span>{t("back")}</span>
        //     </button>
        // </div>
  )
}

export default TopicsNav