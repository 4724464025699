import { useTranslation, Trans } from 'react-i18next';

function Title({app}){
    const { t, i18n } = useTranslation();
  
    if (app === "cat"){
        return (
            <div className = " flex flex-col justify-end items-center w-10/12 mt-2 text-center text-xl text-mytitle " >
                <div>
                {t("site_titlel1")}
                </div>
                {/* <hr className='w-full mt-2 border-mycomplementary bg-mycomplementary rounded'/> */}
            </div>
            // </div>
        )
    
    } else {
        return (
            <div className = " flex flex-col justify-end items-center w-10/12 mt-2 text-center text-xl text-mytitle " >
                <div>
                {t("bible_titlel1")}
                </div>
                <div className='text-sm'>
                {t("bible_titlel2")}
                </div>
                {/* <hr className='w-full mt-2 border-mycomplementary bg-mycomplementary rounded'/> */}
            </div>
            // </div>
        )
    }
}

export default Title