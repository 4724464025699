import { useTranslation, Trans } from 'react-i18next';
import {ArabicRegex, howArabic, EnglishRegex} from "../ArabicRegex";
import TopicDirectory from './TopicDirectory';
import {useDidUpdateEffect} from "../utils"
import Options from './Options';
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import { useState } from 'react';


function MatchedTopics({data, searchText, topicView, setTopicView, view, setView, setLangAppState}) {
    const { t, i18n } = useTranslation();
    const [clicked, setClicked] = useState(false)

    useDidUpdateEffect(() => {
        if(((topicView.match(/\//g) || []).length !== 1)){
        setView("topics")
    }
    }, [clicked])

    let page = []
    let matched = []
    let counter = 0
    let test_counter = 0
    const searching_in_arabic = (howArabic(searchText) === 1)
    const searching_in_english = (howArabic(searchText) === 0)
    const lang_is_arabic = i18n.resolvedLanguage==="ar"

    let name = "name"
    let letters;
    let index;
    let regex_string;
    let regexObj;
    if (lang_is_arabic){
        letters = ["أ", "ب", "ت", "ث", "ج", "ح", "خ", "د", "ذ", "ر", "ز", "س", "ش", "ص", "ض", "ط", "ظ", "ع", "غ", "ف", "ق", "ك", "ل", "م", "ن", "ه", "و", "ي"]
        index = data.arabic_index
        regex_string = ArabicRegex(searchText)
        regexObj =  new RegExp(regex_string)
    
    } else {
        letters = ["a", "b", "c", "d", "e", "f", "g", "h", "j", "k", "l", "m", "n", "o", "p", "qr", "s", "t", "u", "v", "wxyz" ]
        index = data.english_index
        regex_string = EnglishRegex(searchText)
        regexObj =  new RegExp(regex_string, "i")

    }

    function handleOnClick_match(indices, event){
        event.preventDefault()
        
        if (indices.length === 2){
            setTopicView(indices[0] + "/" + indices[1].toString() + "/")
            setClicked((clicked) => !clicked)

        } else if (indices.length === 3){
            setTopicView(indices[0] + "/" + indices[1].toString() + "/" + indices[2].toString() + "/" )
            setClicked((clicked) => !clicked)


        } else if (indices.length === 4) {
            setTopicView(indices[0] + "/" + indices[1].toString() + "/" + indices[2].toString() + "/" + indices[3].toString() + "/" )
            setClicked((clicked) => !clicked)


        } else if (indices.length === 5){
            setTopicView(indices[0] + "/" + indices[1].toString() + "/" + indices[2].toString() + "/" + indices[3].toString() + "/" + indices[4].toString() + "/")
            setClicked((clicked) => !clicked)
   
        } 
    }


    let text;
    for (let li = 0; li < letters.length; li++){
        const letter = letters[li]
        test_counter += index[letter].length

        for (let i = 0; i < index[letter].length; i++){
            text = " " + index[letter][i][name] + " "
            if (regexObj.test(text)) {

                page.push(<div className='p-2 cursor-pointer'
                                onClick={(event) => handleOnClick_match([letter, i], event)}>
                <TopicDirectory data={data} matched_text={index[letter][i][name]} regex_string={regex_string} indices={[letter, i]} setTopicView={setTopicView}/> 
                </div>)
            }
            if (index[letter][i].hasOwnProperty("children")){
                test_counter += index[letter][i].children.length
                for (let j = 0; j < index[letter][i].children.length; j++){
                    text = " " + index[letter][i].children[j][name] + " "
                    if (regexObj.test(text)) {
                        page.push(<div className='p-2 cursor-pointer'
                        onClick={(event) => handleOnClick_match([letter, i, j], event)}>
                        <TopicDirectory data={data} matched_text={index[letter][i].children[j][name]} regex_string={regex_string} indices={[letter, i, j]} setTopicView={setTopicView} />
                        </div>)
                    }
                    
                    if (index[letter][i].children[j].hasOwnProperty("children")){
                        test_counter += index[letter][i].children[j].children.length
                        for (let k = 0; k < index[letter][i].children[j].children.length; k++){
                            text = " " + index[letter][i].children[j].children[k][name] + " "
                            if (regexObj.test(text)) {
                                page.push(<div className='p-2 cursor-pointer'
                                onClick={(event) => handleOnClick_match([letter, i, j, k], event)}>
                                    <TopicDirectory data={data} matched_text={index[letter][i].children[j].children[k][name]} regex_string={regex_string} indices={[letter, i, j, k]} setTopicView={setTopicView}/>
                                    </div>)

                            }

                            if (index[letter][i].children[j].children[k].hasOwnProperty("children")){
                                test_counter += index[letter][i].children[j].children[k].children.length
                                for (let l = 0; l < index[letter][i].children[j].children[k].children.length; l++){
                                    text = " " + index[letter][i].children[j].children[k].children[l][name] + " "
                                    if (regexObj.test(text)) {
                                        page.push( <div className='p-2 cursor-pointer'
                                                    onClick={(event) => handleOnClick_match([letter, i, j, k, l], event)}>
                                        <TopicDirectory data={data} matched_text={index[letter][i].children[j].children[k].children[l][name]} regex_string={regex_string} indices={[letter, i, j, k, l]} setTopicView={setTopicView}/>
                                        </div>)
                                    }
                                }

                            }
                        }

                    }
                            
                }

            }
        }
    }

    console.log(test_counter)
    counter = page.length

    return (
        <div className="mt-8 mb-0 border-2 shadow-md rounded-sm bg-mysearchcardbg">
        <div className='text-myblue text-lg ms-3 mt-3 mb-3 '>
        { counter?
            <span className='font-bold'>{t("pre_search_text_topics")}</span>
            : <span className='text-red-800'>{t("no_results_topics")}</span>

        }
            "<span className='bg-yellow-200'>{searchText}</span>"
            <div className="text-sm">{t("results")}{counter}</div>
            <div className='text-sm mt-2'>
            {counter? <><IconContext.Provider value={{className: "inline global-class-name align-center text-sm" }}>
                    <IoIosInformationCircleOutline /> 
                </IconContext.Provider>
                <span className="ms-1">{t("topics_results_info")}</span></>: <></>}
            </div>
        </div>
        { searching_in_english&&lang_is_arabic?
            <div className='ms-3' > يبدو أن بحثك يحتوى على كلمات انجليزية، اذا كنت تريد البحث في النسخة الانجليزية <span className='clickable text-myblue' onClick={() => setLangAppState("en")}>اضغط هنا</span></div>
            : <></>
        }
        { searching_in_arabic&&!lang_is_arabic?
            <div className='ms-3' > It seems that your search contains Arabic words, if you want to search in the Arabic version <span className='clickable text-myblue' onClick={() => setLangAppState("ar")}> click here </span></div>
            : <></>
        }
        <div className='flex flex-col divide-y border-t mt-3'>
            {page}
        </div>
        </div>
  )
}

export default MatchedTopics