import { useTranslation, Trans } from 'react-i18next';

function Footer({setView}){
    const { t, i18n } = useTranslation();

    return(
        <div className="text-sm bg-myheader shadow-sm border-b border-mycomplementary p-4 mb-5 flex flex-col content-center items-center whitespace-pre-wrap">
            <div className='w-10/12 text-center'>
                {t("made_by")}
            </div>
            <div className="w-10/12 text-gray-500 text-center">
                Beta Version 0.3
            </div>
            <div className="w-10/12 text-center">Under <a className='clickable text-primary' href="https://creativecommons.org/licenses/by-nc/4.0/deed">Creative Commons NonCommercial license</a>, 2024</div>
            <div className='w-10/12 text-center'>
                <span>{t("goto_info")}</span>
                <span className=' clickable text-primary' onClick={() => setView("meta_info")}>
                    {t("click_here")}
                </span>
            </div>
        </div>
    )
}

export default Footer