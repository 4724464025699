import React from 'react'

function Options({children}) {
  return (
    <>
        <div className='mb-4 mt-6 flex flex-col gap-1 text-sm'>{children}</div>
        <hr className='w-full'/>
    </>
      
  )
}

export default Options
