import {useEffect} from "react"
import RenderLetterTopics from './RenderLetterTopics'
import LetterPicker from './LetterPicker'
import { useTranslation, Trans } from 'react-i18next';
import TopicView from './TopicView'
import { howArabic } from '../ArabicRegex.js';

function TopicsPage({data, topicView, setTopicView, translateAll, setTranslateAll}) {
  const { t, i18n } = useTranslation();
  
    // fe kol el components bta3t el topic page bnt3amel bs m3 el langAppState

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [topicView])

  const topiclang =   howArabic(topicView[0]) === 0 ?  "en" : "ar"

  return (
      <div className='mx-4'>
      {((topicView.match(/\//g) || []).length === 1)? 
        (<><LetterPicker  topicView={topicView} setTopicView={setTopicView} langAppState={topiclang}/>
          <RenderLetterTopics  data={data} topicView={topicView} setTopicView={setTopicView} langAppState={topiclang}/></>
          )
        :<TopicView topicView={topicView} setTopicView={setTopicView} data={data} translateAll={translateAll} setTranslateAll={setTranslateAll} langAppState={topiclang}/>
      }
      {((topicView.match(/\//g) || []).length === 1)? <div className='mt-10'><LetterPicker topicView={topicView} setTopicView={setTopicView} langAppState={topiclang} info={false}/></div> : <></>}
    </div>
  )
}

export default TopicsPage

