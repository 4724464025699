import React from 'react'
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import { useTranslation, Trans } from 'react-i18next';

function SearchInfo() {
    const { t, i18n } = useTranslation();

    if (i18n.resolvedLanguage=="ar"){
      return (
        <div className='whitespace-pre-wrap mt-6'>
        <div className='text-sm'>  للعودة الى تلك الصفحة مرة أخرى في أي وقت، اكتب علامة استفهام في صندوق البحث</div>
        <div className='text-lg text-myblue mt-4'> كيفية استخدام البحث </div>
<div className="mt-2">
أولا أنت لا تحتاج لقراءة هذه الصفحة قبل استخدام البحث، يمكنك استخدام البحث مباشرةً و ستجده يعمل تمامًا كما تتوقعه، و لكن لتسهيل الوصول الى النتائج المقصودة، هناك بعض الأدوات التي يمكنك استخدامها وبعض التقديرات التي يأخذها البرنامج لكي يجمع كل النتائج التي "غالبًا" تقصدها و يمنع النتائج التي "غالبًا" لا تقصدها.
</div>
<div className="mt-4">
هناك طريقتين لاستخدام البحث:
</div>
<div className="text-lg text-myred mt-2">
1) بأرقام الفقرات
</div>
<div className="mt-2">
اذا كنت تريد إظهار فقرة (أو مجموعة فقرات) و تعلم رقم الفقرة التي تريدها، يمكنك كتابة رقم الفقرة، أو مجموعة أرقام للفقرات مفصولين بفاصلة أو مسافة. يمكنك أيضًا أن تستبدل أي رقم برقمين بينهم شرطة "-" لإظهار مدى الفقرات بين الرقم الأول و الثاني
</div>
<div>
مثلا: البحث عن <span className='bg-yellow-100'>22، 1236، 652-649</span> سيظهر لك الفقرات 22 و 1236 و 649 و 650 و 651 و 652
</div>
<div className="mt-2">
قد تجد هذه الطريقة مفيدة اثناء قراءة نص يستشهد أو يشير الى فقرات الكاتيكيزم بالأرقام (في الهوامش او داخل النص)، أو اثناء قراءة أحد تعاليم الكنيسة المعتمدة على الكاتيكيزم مثل اليوكات (التعليم المسيحي للشباب) عادةً في مثل هذه الكتب يُكتب أرقام الفقرات للرجوع اليها. إن كنت تريد وقتها أن تقرا النص كاملًا (أو سياقه) أو تريد أن تقرأه بالعربية و الانجليزية، يمكنك ببساطة أن تكتب الرقم هنا
</div>
<div className="text-lg text-myred mt-4">
2) البحث بالكلمات
</div>
<div className="mt-2">
يمكنك كتابة أي كلمة أو جملة للبحث عنها في الموضوعات، وعناوين الفهرس، و نصوص الفقرات. أيضًا تلك الطريقة تعمل كما تتوقعها، لكن قد يكون مفيدًا أن تأخذ الاتي في اعتبارك عند البحث:
</div>
<div className="mt-2"> &#x2022;
النتائج في البحث عن الكلمات تكون بمماثلة الكلمة كاملة منفصلة و ليس بظهور أحرفها في كلمة أخرى. فمثلا نتائج البحث عن <span className='bg-yellow-100'>المسيح</span> لا تماثل نتائج البحث عن <span className='bg-yellow-100'>المسيحيين</span>
</div>
<div className="my-2"> &#x2022;
في اللغة العربية، هناك بعض الفروقات التي لا يُشدّد عليها في البحث لتسهيل الوصول الى النتائج المقصودة: 
</div>
<div className="ms-3">
- لا يُشدّد على الألف و اللام في أول الكلمة، فمثلا البحث عن <span className='bg-yellow-100'>أسرار</span>، سيعطيك النتائج التي تحتوى على <span className='bg-yellow-100'>الأسرار</span>، و العكس صحيح
</div>
<div className="ms-3">
- لا يُشدد على الفروق بين أنواع الألف و مكان الهمزة، فمثلا البحث عن <span className='bg-yellow-100'>افخارستيا</span>، سيعطيك أيضًا النتائج التي تحتوي على <span className='bg-yellow-100'>إفخارستيا</span> و <span className='bg-yellow-100'>أفخارستيا</span>.
</div>
<div className="ms-3">
- لا يُشدّد على التشكيل، فمثلا البحث عن <span className='bg-yellow-100'>التجدد</span> سيعطيك أيضًا النتائج التي تحتوي على <span className='bg-yellow-100'>التجدُّد</span> 
</div>
<div className="ms-3">
- لا يُشدّد على الفرق بين ال ي و ال ى في آخر الكلمات فمثلا البحث عن <span className='bg-yellow-100'>سيأتى</span> سيعطيك أيضًا نتائج <span className='bg-yellow-100'>سيأتي</span>
</div>
<div className="ms-3">
- لا يُشدّد على الفرق بين ال ة و ال ه في نهاية الكلمة، فمثلا البحث عن <span className='bg-yellow-100'>المسيانيه</span> سيعطيك ايضًا نتائج <span className='bg-yellow-100'>المسيانية</span>
</div>
<div className="ms-3">
- لا يُشدد على الألف الممدودة في نهاية الكلمة، مثلا البحث عن <span className='bg-yellow-100'>عهد</span> سيعطيك أيضًا النتائج التي تحتوي على <span className='bg-yellow-100'>عهدًا</span>
</div>


        </div>
      )

    } else {
      return (
        <div className='whitespace-pre-wrap mt-6'>
        <div className='text-sm'> To get back to this page at any time, write a question mark in the search bar</div>
        <div className='text-lg text-myblue mt-4'>How To Use the Search</div>
<div className="mt-2">
First of all, You don't need to read this page before using the search, you can use it directly and you will find it working exactly as expected. But to facilitate getting the results you intend, there are some tools you may find useful:  
</div>
<div className="mt-4">
There are two ways to use the search: 
</div>
<div className="text-lg text-myred mt-2">
1) By Paragraph Numbers
</div>
<div className="mt-2">
If you want to display a certain paragraph (or paragraphs) knowing its number, you can type a paragraph number, or sequence of comma separated (or space separated) numbers. You can also, instead of a simple number, type two numbers separated by a hyphen "-" to display the range of paragraphs between the first and second number.
</div>
<div>For example: searching for <span className='bg-yellow-100'>22، 1236، 652-649</span> will display the paragraphs 22, 1236, 649, 650, 651, and 652.
</div>

<div className='mt-2'>
This method is useful when you are reading something that refers to Catechism paragragraphs by numbers (either in footnotes or within text), or reading a different Catechism-based catechisis like the YOUCAT (they usually refer to the number of full paragraphs in the Catechism). if you want then to read the exact full paragraph (or its context), or read it in Arabic or English, you can simply write the paragraph number here.
</div>
<div className="text-lg text-myred mt-4">
2) Searching By words
</div>
<div className="mt-2">
You can write any word (or words) to search for it in topics, titles, and paragraphs text. This method also works as you expect. But it may be useful to keep the following in mind while using this method:
</div>
<div className="mt-2"> &#x2022;
Results are extracted by the exact match to your search text (as a full separate word), not by being a part of another word. For example: searching for <span className='bg-yellow-100'>Christ</span> doesn't match the word <span className='bg-yellow-100'>Christians</span>
</div>
<div className="my-2"> &#x2022;
In English, the search is not case sensitive. for Example searching for <span className='bg-yellow-100'>Holy</span> will match <span className='bg-yellow-100'>Holy</span> and <span className='bg-yellow-100'>holy</span>
</div>



        </div>
      )
  }
}

export default SearchInfo