import React from 'react'
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import { useTranslation, Trans } from 'react-i18next';


function SearchInfoGoTo({setSearchText}) {
    const { t, i18n } = useTranslation();

  return (
    <div className='text-sm mt-2'>
        <IconContext.Provider value={{className: "inline global-class-name align-center text-sm" }}>
            <IoIosInformationCircleOutline /> 
        </IconContext.Provider>&nbsp;
        {i18n.resolvedLanguage==="ar"?
            <span>
                لمعلومات أكثر عن كيف يعمل البحث، <span className='clickable text-primary' onClick={() => setSearchText("؟")}>اضغط هنا</span>
            </span>   
        :
            <span>
                For more info on how the search function works, <span className='clickable text-primary' onClick={() => setSearchText("?")}> press here </span>
            </span>
    }
</div>
  )
}

export default SearchInfoGoTo