import { useReducer, useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import { useSearchParamsState } from "./useSearchParamsState"
import Catechism from "./Catechism.js"
import Bible from "./Bible.js"
import AppChooser from './components/AppChooser.js';
import LangButtons from './components/LangButtons.js';
import { howArabic } from './ArabicRegex.js';
import {useDidUpdateEffect} from "./utils.js";
import Title from './components/Title.js';
import "react-datetime/css/react-datetime.css";
import Footer from './components/Footer'

function App() {
  const { t, i18n } = useTranslation();
  
  // those are app level state
  const [app, setAppState] = useSearchParamsState("app", "");
  const [langAppState, setLangAppState] = useSearchParamsState("lng", "ar") // de mohema 3shan el links
  const [topicView, setTopicView] = useSearchParamsState("tov", "أ/")
  const [searchText, setSearchText] = useSearchParamsState("q", "");
  const [translateAll , setTranslateAll] = useSearchParamsState("expall", "false")
  const [view, setView] = useSearchParamsState("v", "read")
  const [pagenum, setPagenum] = useSearchParamsState("pgnm", "0@top##0")
  const [tocDepth, setTocDepth] = useSearchParamsState("tocD", "6")
  const [searchIn, setSearchIn] = useSearchParamsState("SIn", "topics")

  const [bibleView, setBibleView] = useSearchParamsState("bview", "books");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
         // remove the loader on mount
         const element = document.getElementById("loader");
         if (element) element.remove()
       }, []);

  useEffect(() => {
    if (searchParams.get("app") === null) {
      searchParams.set("app", "cat");
      setSearchParams(searchParams);
    }
    if (searchParams.get("lng") === null) {
      searchParams.set("lng", "ar");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    i18n.changeLanguage(langAppState) 
  }, [langAppState])

  i18n.on('languageChanged', function(lng) {
    const first_letter = topicView[0]
    if (lng === "ar"){
      if (howArabic(first_letter)===0) setTopicView("أ/") // else seb el topic view zy mahowa
    } else {
      if (howArabic(first_letter)===1) setTopicView("a/") // else seb el topic view zy mahowa
    }
   })

   useDidUpdateEffect(() => {
    if (view!== "read"){
      setView("read")
    }
  }, [pagenum])

  // de 3han el enter fel searchbar twadeny 3ala view el search, w kman el enter (submit) y-scroll lelnatayeg
  useDidUpdateEffect(() => {
    if (view === "search"){
      setTimeout(() => {
        const element = document.getElementById("search_results");
        if (element) element.scrollIntoView({ behavior: "smooth"});
      }, 0)
    } else {
      setView("search")
    }
  }, [searchText])

  return (
    <div className="App whitespace-normals arabic">
      <div dir={i18n.resolvedLanguage === "ar"? "rtl": "ltr"}
       className="container mx-auto bg-background max-w-prose">
        <div className="bg-myheader flex flex-col items-center gap-2 pb-4 shadow-md">
          <AppChooser app={app} setAppState={setAppState}/>
          <Title  app={app}/>
          { app === "cat" && <LangButtons langAppState={langAppState} setLangAppState={setLangAppState}/>}
        </div>
        {app === "cat" && <Catechism langAppState={langAppState} setLangAppState={setLangAppState} topicView={topicView} setTopicView={setTopicView} view={view} setView={setView} pagenum={pagenum} setPagenum={setPagenum} tocDepth={tocDepth} setTocDepth={setTocDepth} searchIn={searchIn} setSearchIn={setSearchIn} translateAll={translateAll} setTranslateAll={setTranslateAll} searchText={searchText} setSearchText={setSearchText}></Catechism>}
        {app === "bib" && <Bible bibleView={bibleView} setBibleView={setBibleView}></Bible>}
        <Footer setView={app === "cat"? setView : setBibleView}/>

      </div>
    </div>
  );

}

export default App;
