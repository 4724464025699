import React from 'react'
import { useTranslation, Trans } from 'react-i18next';


function TestamentChooser({testament, setTestament}) {
    const { t, i18n } = useTranslation();

    let handle_old_click = () => {
        setTestament("old")
    }
    let handle_new_click = () => {
        setTestament("new")
    }


  return (
    <div className='mt-6 mb-2 flex flex-row gap-3'>
        <div className={'border rounded-sm py-1 px-2 cursor-pointer' + (testament === "old"? " bg-mycomplementary text-white shadow-sm cursor-pointer " : "text-black bg-inherit shadow-md cursor-pointer ")} onClick={handle_old_click}>{t("old_testament")}</div>
        <div className={'border rounded-sm py-1 px-2 cursor-pointer' + (testament === "new"? " bg-mycomplementary text-white shadow-sm cursor-pointer " : "text-black bg-inherit shadow-md cursor-pointer ")}onClick={handle_new_click}>{t("new_testament")}</div>
    </div>
  )
}

export default TestamentChooser