import React from 'react'
import { get_parent_class } from '../utils'
import {howArabic} from "../ArabicRegex";

function ParentDirectory({data, toc_index, match_regex=""}) {

    const searching_in_arabic = (howArabic(match_regex) !== 0)
    let name = "name"
    if(searching_in_arabic) name = "arabicName"

    const toc = data.toc
    let output = [toc[toc_index]]
    let jsx_output = []
    let lowest_level = toc[toc_index].absolute_level

    for (let i = toc_index-1; i > 0; i--){
        // 3ayez kol entry el absolute level bta3etha a2al mn elly ba3deha w 2a2al mn el 2a2al
        if (toc[i].absolute_level < toc[i+1].absolute_level && toc[i].absolute_level < lowest_level ){
            lowest_level = toc[i].absolute_level
            output.push(toc[i])
        }
    }

    for (let i = output.length-1; i >= 1; i--){
        // if match is arbic get arabic name else get name
        // if contains match construct it else text is normal arabic name or name

        let text = " " + output[i][name] + " "

        let parent_class_name = get_parent_class(output[i].absolute_level) + " font-normal "
        jsx_output.push(<span><span className={parent_class_name}>{text}</span>
            <span className='font-bold'>/ </span>
        </span>)
    }

    // rendering the child 
    let text = " " + output[0][name] + " "

    if (match_regex) {
        const regexObj = new RegExp(match_regex, "gi")
        const match = regexObj.exec(text)

        if (match){
            const before_match = text.substring(0, match.index + 1)
            let stripped_match_text = match[0].trim(" ")
            const rest = text.substring(match.index + 1 + stripped_match_text.length)
            text = <> {before_match}<span className='bg-yellow-200 h-full text-black'>{stripped_match_text}</span>{rest} </>
        }
    }

    let parent_class_name = get_parent_class(output[0].absolute_level)
    jsx_output.push(<span><span className={parent_class_name + " font-bold "}>{text}</span>
    </span>)

  return (
    <>{jsx_output}</>
  )
}

export default ParentDirectory
