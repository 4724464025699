import React from 'react'
import { useTranslation, Trans } from 'react-i18next';


function TopicDirectory({data, matched_text, regex_string, indices, setTopicView}) {
    const { t, i18n } = useTranslation();

    const regexObj = new RegExp(regex_string, "ig")
    matched_text = " " + matched_text + " "
    const match_array = [...matched_text.matchAll(regexObj)];

    let result_text_jsx = [];
    let last_index = 0
    match_array.forEach((match) => {
      let stripped_match_text = match[0].trim(" ")
      result_text_jsx.push(<>{matched_text.substring(last_index, match.index + 1)}</>)
      result_text_jsx.push(<span className='bg-yellow-200'>{stripped_match_text}</span>)
      last_index = match.index + 1 + stripped_match_text.length
    })
    result_text_jsx.push(<>{matched_text.substring(last_index)}</>)

    let base_array = []
    if (i18n.resolvedLanguage === "ar"){
        base_array = data.arabic_index[indices[0]]
    } else {
        base_array = data.english_index[indices[0]]
    }

    let i, j, k, l;
    if (indices.length === 2){
        i = indices[1]
        // only black
        return (
            <div>
                <span className='font-bold'>{result_text_jsx}</span>
            </div>
          )
        

    } else if (indices.length === 3){
        i = indices[1]
        j = indices[2]
        // black and blue
        return (
            <div>
                <span className='font-bold'>{base_array[i].name}</span>/
                <span className='text-myblue'> {result_text_jsx}</span>
            </div>

            )
        
    } else if (indices.length === 4) {
        i = indices[1]
        j = indices[2]
        k = indices[3]
        // black and blue and red
        return (
            <div>
                <span className='font-bold'>{base_array[i].name}</span>/
                <span className='text-myblue'> {base_array[i].children[j].name}</span>/
                <span className='text-myred'> {result_text_jsx}</span>

            </div>

          )
        

    } else if (indices.length === 5){
        i = indices[1]
        j = indices[2]
        k = indices[3]
        l = indices[4]
        //black an blue and red and green
        return (
            <div>
                <span className='font-bold'>{base_array[i].name}</span>/
                <span className='text-myblue'> {base_array[i].children[j].name}</span>/
                <span className='text-myred'> {base_array[i].children[j].children[k].name}</span>/
                <span className='text-mygreen'> {result_text_jsx}</span>


            </div>
          )
        

    }

}

export default TopicDirectory