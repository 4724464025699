import Parent from "./Parent"
import Paragraph from "./Paragraph"
import Options from './Options';
import TranslateAllCheckbox from './TranslateAllCheckbox.js';
import Navigation from './Navigation.js'
import {pagenum_string_setter, pagenum_string_getter} from "../utils.js"

function Page({translateAll, setTranslateAll, pagenum, setPagenum, data}) {

    const current_pagenum = pagenum_string_getter(pagenum, "pagenum")

    const [start, end] = data.pages[current_pagenum]
    const toc = data.toc
    let content = [];
    let last_rendered_parent_index = 0
    let rendered_paragraph_flag = false
    for (let j = 0; j< data.toc.length; j++){ //loop over all toc

        const toc_start = toc[j].range[0] 
        const toc_end = toc[j].range[1]
        let last_toc_start = toc[last_rendered_parent_index].range[0]
        let last_toc_end = toc[last_rendered_parent_index].range[1]
        if(last_toc_start < start) last_toc_start = start 
        //law el saf7a alabet fel nos ebtedy mn el bdaya

        //mabda2yan 7ot el parents elly bta7tawy el saf7a
        if(toc_start < start && toc_end > end){
            if(rendered_paragraph_flag) content.push(<Parent parent={toc[j]} />) // then put parent
            else content.push(<Parent parent={toc[j]} page_mt=" mt-2 " />) 
            rendered_paragraph_flag = false
            continue
        } else if(toc_start < start) continue //ys3ny el toc_end not > end

        if (toc_start <= end){
            // w talama wselna lhena hya aked akbr btbtdy b3d el start
            
            //loop from last start to this toc, rendering all the paragraphs
            // (render difference bbefore putting the parent) paragraphs first then parent

            for(let i = last_toc_start; i < toc_start; i++){ 
                content.push(<Paragraph translateAll={translateAll} pnum={i} data={data} card="translate"/>)
                rendered_paragraph_flag = true
            }

            if(rendered_paragraph_flag) content.push(<Parent parent={toc[j]} />) // then put parent
            else content.push(<Parent parent={toc[j]} page_mt=" mt-2 " />) 
            rendered_paragraph_flag = false

            last_rendered_parent_index = j
            last_toc_start = toc[last_rendered_parent_index].range[0]

  
        } else if (last_toc_start >= start && last_toc_start <= end && last_toc_end >= end){
            // if last parent is the last in page

            // render last pragraphs
            for(let i = last_toc_start; i <= end; i++){ 
                content.push(<Paragraph translateAll={translateAll} pnum={i} data={data} card="translate"/>)
                rendered_paragraph_flag = true
            }
            break
        } else break
        }
    if ( current_pagenum==="83") {
        //render the last of all catechism from 2857 2865
        for(let i = 2857; i <= 2865; i++){
            content.push(<Paragraph translateAll={translateAll} pnum={i} data={data} card="translate"/>)
            rendered_paragraph_flag = true
        }
    }
    
    return (
    <div>
        <Options>
            <TranslateAllCheckbox 
                    translateAll={translateAll} 
                    setTranslateAll={setTranslateAll}
                />
        </Options>
        <Navigation pagenum={pagenum} setPagenum={setPagenum}/>
        {content}
        <Navigation pagenum={pagenum} setPagenum={setPagenum}/>
    </div>
  )
}

export default Page
