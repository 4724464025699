import TOC from './TOC.js'
import Page from './Page.js'
import {pagenum_string_setter, pagenum_string_getter} from "../utils.js"
import { useEffect } from 'react'

function ReadPage({data, translateAll, setTranslateAll, pagenum, setPagenum, tocDepth, setTocDepth}) {

    // lamma t5osh el readpage (aw el pagenum yt8ayar) lw el pagenum feha scroll ID scroll leh 
    useEffect( () => {
        const scrollID = pagenum_string_getter(pagenum, "scrollID")
        if (scrollID == "top"){
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

        } else{
            const element = document.getElementById(scrollID);
            if (element) {
                element.scrollIntoView({ behavior: "smooth"});
                // setPagenum(pagenum_string_setter(pagenum, "scrollID", "0"))
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                console.log("error didn't find element with ID")
                console.log(scrollID)
            }
        }
    } ,[pagenum])

    return (
        <div >
            <div className='px-4'>
                {Number(pagenum_string_getter(pagenum, "pagenum"))===0?
                    <TOC  pagenum={pagenum} setPagenum={setPagenum} data={data} tocDepth={tocDepth} setTocDepth={setTocDepth} />:
                <Page translateAll={translateAll} setTranslateAll={setTranslateAll} pagenum={pagenum}  setPagenum={setPagenum} data={data} />}
            </div>
        </div>
    )
}

export default ReadPage