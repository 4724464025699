import React from 'react'
import { useTranslation, Trans } from 'react-i18next';
import Indented from './Indented';

function Parent({parent, onClick, toc, indentation_level = 0, page_mt=" mt-6 "}) {
    const { t, i18n } = useTranslation();

     let parent_class_name = ""
    if(toc) {parent_class_name += " cursor-pointer "}
    switch(parent.absolute_level) {
        case 1: //PART
            if(toc){
                parent_class_name += "mt-8 text-lg font-bold "
            } else {
                parent_class_name += " text-lg font-bold "
            }
        break;
        case 2: //SECTION
            if(toc){
                parent_class_name += " text-lg font-bold text-mybrown "
            } else {
                parent_class_name += " text-lg font-bold text-mybrown "
            }
        break;
        case 3: //CHAPTER
            if(toc){
                parent_class_name += " text-lg font-bold text-mygreen "
            } else {
                parent_class_name += " text-lg font-bold text-mygreen "
            }
        break;
        case 4: //ARTICLE
            if(toc){
                parent_class_name += " font-bold text-lg text-myblue " 
            } else {
                parent_class_name += " text-lg font-bold text-myblue" 
            }
        break;
        case 5: //PARAGRAPH
            if(toc){
                parent_class_name += " text-myblue "
            } else {
                parent_class_name += " text-lg font-bold text-myblue "
            }
        break;
        case 6: //NUMBERED
            if(toc){
                parent_class_name += " text-myred "
            } else {
                parent_class_name += " text-lg font-bold text-myred "
            }
        break;
        case 7: //paragraphs header (not rendered in toc)
            if(toc){
                parent_class_name += " text-mygreen "
            } else {
                parent_class_name += " text-lg text-mygreen "
            }

            parent_class_name += "  "
        break;
    }

    let name = "name"
    if (i18n.resolvedLanguage==="ar"){
        name = "arabicName"
    }

    return (
        <div id={parent[name]} onClick={onClick} key={parent.key} className={(toc? " ": page_mt) + parent_class_name}>
            <Indented indentation_level={indentation_level}><span className={(toc? 'clickable': " ")}>{parent[name]}</span></Indented>
        </div>
    )
}

export default Parent