import React from 'react'
import { useTranslation, Trans } from 'react-i18next';
import Markdown from 'react-markdown'


function ReadingCard({reading, order, total, bible_data}) {
    const { t, i18n } = useTranslation();

    let book_address = []; 
    let bible_books = ["gen", "ex", "lev", "num", "deut", "josh", "judg", "ruth", "1sam", "2sam", "1kings", "2kings", "1chron", "2chron", "ezra", "neh", "tob", "jth", "est", "1macc", "2macc", "job", "ps", "prov", "eccles", "song", "wisdofsol", "sir", "isa", "jer", "lam", "bar", "ezek", "dan", "hos", "joel", "amos", "obad", "jonah", "mic", "nah", "hab", "zeph", "hag", "zech-2", "mal", "matt", "mac", "luke", "john", "acts", "rom", "1cor", "2cor", "gal", "eph", "phil", "col", "1thess", "2thess", "1tim", "2tim", "titus", "philem", "heb", "james", "1pet", "2pet", "1john", "2john", "3john", "jude", "rev"]
    let acts_flag = false
    let parts = reading.split(";")
    let last_book = ""
    parts.forEach(part => {
        let found_flag = false
        bible_books.forEach(bible_book => {
            if ((" " + part).includes(" " + bible_book + " ")){
                 book_address.push({[bible_book]: part.trim().replace(bible_book, "").trim()})
                 found_flag = true
                 last_book = bible_book
            }
        })
        if (found_flag === false) {
            book_address.push({ [last_book]: part.trim()})
        }
    });

    let title = ""
    let text = ""
    book_address.forEach((dict, index) => {
        let book = Object.keys(dict)[0]
        if (book === "acts") acts_flag = true

        // construct title
        if (index == 0) {last_book = book}
         if (title === ""){
             title = t(order + "-read") + t("from") + t(book) + " (" + dict[book]  + ")"
         } else {
            title += t("and")
            if (book !== last_book) { title += t("from") + t(book) + " " }
            title +=  "(" + dict[book] + ")"
         }
         last_book = book

         // construct text
         let chapter_match = dict[book].match(/([0-9]{1,2})[ ]?\:/)
         let chapter = Number(chapter_match[1])
         let verses = dict[book].replace(chapter_match[0], "").trim()
        //  console.log(verses)
         let hyphen_pos = verses.indexOf("-")
         if(hyphen_pos > 0){
            let start_verse = verses.substring(0, hyphen_pos)
            let end_verse = verses.substring(hyphen_pos + 1)

            let chapter_text = bible_data.arabic[book][chapter-1]
            let start_search_pos = chapter_text.indexOf("=")
            let start_pos =  chapter_text.indexOf(start_verse, start_search_pos)
            let end_pos =  chapter_text.indexOf(String(Number(end_verse)+1), start_search_pos)

            if (end_pos !== -1) {
                text += chapter_text.substring(start_pos, end_pos)
            } else {
                text += chapter_text.substring(start_pos)
               
            }
         } else {
            let start_verse = verses
            let chapter_text = bible_data.arabic[book][chapter-1]
            let start_search_pos = chapter_text.indexOf("=")
            let start_pos =  chapter_text.indexOf(start_verse, start_search_pos)
            let end_pos =  chapter_text.indexOf(String(Number(start_verse)+1), start_search_pos)

            if (end_pos !== -1) {
                text += chapter_text.substring(start_pos, end_pos)
            } else {
                text += chapter_text.substring(start_pos)
            }

         }
         //  console.log(bible_data.arabic[book][chapter-1])
        //  console.log(bible_data.arabic[book])
    })

// 3ayzen ne3ml 7agteen fel text, nshel el arkam w nshel el 3anaween

text = text.replace(/[0-9]/g, "")
text = text.replace(/\n#.*\n/g, "")
text = text.replace(/^#.*\n/g, "")
text = text.replace(/\n\*.*\*\n/g, "")
text = text.replace(/^\*.*\*\n/g, "")
text = text.replace(/> \*.*\*\n/g, "")
text = text.replace(/=/g, "")

return (
    <div className='mb-6 p-4 rounded-md border shadow-md '>
        { total === order ? <div className='text-myred font-bold text-xl'>{title}</div> :
          total === order + 1 ? <div className='text-myblue font-bold text-lg'>{title}</div> :
          acts_flag ? <div className='text-myblue font-bold text-lg'>{title}</div>:
            <div className='font-bold'>{title}</div>
        }
        <div className='mt-3'><Markdown>{text}</Markdown></div>
        </div>
  )
}

export default ReadingCard