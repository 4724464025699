import React from 'react'
import { LuFileVolume2 } from 'react-icons/lu'

function Indented({onClick, indentation_level, children}) {
  // every level will result to number of pixels margins
  //the padding is always 4
  // in rendering ignore el difference between this level and last level
  // hanshta3'al b indented 3n el last level
  
  let lines_elements = []
  for (let i = 0; i < indentation_level ; i++){
    lines_elements.push(
      <span className ="border-s-2 ps-4">&#8203;</span>
    )
  }
  if (indentation_level === 0){
    return <span onClick={onClick} className=' mt-1'>{children}</span>
  }
  return (
    <span className="whitespace-pre-wrap inline-flex justify-start justify-items-start items-stretch">
    {lines_elements}
    <span onClick={onClick} className=' mt-1'>{children}</span>
    </span>
  )
}

export default Indented

  // | level 1 
  // | | Level2 
  // | | | level 3