import moment from 'moment';
import { stringify } from 'postcss';
// 6 jan da ayman 3ed el deneh 



export function from_gregorian_to_liturgy(date_string){
    let fes7_dates = {
        "2014": "20/4/2014",
        "2015": "5/4/2015",
        "2016": "27/3/2016",
        "2017": "16/4/2017",
        "2018": "1/4/2018",
        "2019": "21/4/2019",
        "2020": "12/4/2020",
        "2021": "4/4/2020",
        "2022": "17/4/2022",
        "2023": "9/4/2023",
        "2024": "31/3/2024",
        "2025": "20/4/2025",
        "2026": "5/4/2026",
        "2027": "28/3/2027",
        "2028": "16/4/2028",
        "2029": "1/4/2029",
        "2030": "21/4/2030",
        "2031": "13/4/2031",
        "2032": "28/3/2032",
        "2033": "17/4/2033",
        "2034": "9/4/2034",
    }

    // the algorithm
    // 3ed el keyama
    // erga3 ableha b 40 yoom bdayet mosem el som

    let momobj = moment(date_string, "D/M/YYYY")
    let day_of_year =  momobj.dayOfYear()
    let current_year = momobj.year()
    let day = momobj.format("ddd")

    let fes7 = moment(fes7_dates[current_year], "D/M/YYYY") 
    // console.log(fes7.format("D/M/YYYY"))

    let deneh_start = moment("6/1/" + current_year, "D/M/YYYY")
    while(true) {
        if(deneh_start.format("ddd") === "Sun") break
        else deneh_start.day(deneh_start.day() + 1)
    } 

    let som_start = moment(String(fes7.dayOfYear() - 7*7) + "/" + current_year, "DDD/YYYY")
    let rosol_start = moment(String(fes7.dayOfYear() + 7*7) + "/" + current_year, "DDD/YYYY")
    let toba_start = moment(String(fes7.dayOfYear() + 14*7) + "/" + current_year, "DDD/YYYY")
    let elija_start = moment(String(fes7.dayOfYear() + 21*7) + "/" + current_year, "DDD/YYYY")
    let mosa_start = moment(String(fes7.dayOfYear() + 28*7) + "/" + current_year, "DDD/YYYY")

    let melad_start = moment("25/12/" + current_year, "D/M/YYYY")
    // while(true) {
    //     if(melad_start.format("ddd") === "Sun") break
    //     else melad_start.day(melad_start.day() + 1)
    // }

    let beshara_start = moment(String(melad_start.dayOfYear() - 4*7) + "/" + current_year, "DDD/YYYY")
    let takdes_start = moment(String(melad_start.dayOfYear() - 8*7) + "/" + current_year, "DDD/YYYY")

    // law e7na ba3d melad _start yb2a aked esboo3 awwal 
    // 7alet awel el sana abl el deneh yb2a nshof el las melad kan eh nzamo
    let zaman;
    let weeks = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth" ]
    let week;

    if (day_of_year >= deneh_start.dayOfYear() && day_of_year < som_start.dayOfYear()){
        zaman = "deneh";
        week = weeks[Math.floor((day_of_year - deneh_start.dayOfYear())/7)]
        if(day_of_year === som_start.dayOfYear() - 20) return `${day}/${week}/${zaman}/1st-ba3otha`
        else if (day_of_year === som_start.dayOfYear() - 19) return `${day}/${week}/${zaman}/2nd-ba3otha`
        else if (day_of_year === som_start.dayOfYear() - 18) return `${day}/${week}/${zaman}/3rd-ba3otha`

    }
    else if (day_of_year >= som_start.dayOfYear() && day_of_year < fes7.dayOfYear()){ zaman = "som"; week = weeks[Math.floor((day_of_year - som_start.dayOfYear())/7) ]}
    else if (day_of_year >= fes7.dayOfYear() && day_of_year < rosol_start.dayOfYear()){ zaman = "keyama"; week = weeks[Math.floor((day_of_year - fes7.dayOfYear())/7) ]}
    else if (day_of_year >= rosol_start.dayOfYear() && day_of_year < toba_start.dayOfYear()){ zaman = "rosol"; week = weeks[Math.floor((day_of_year - rosol_start.dayOfYear())/7) ]}
    else if (day_of_year >= toba_start.dayOfYear() && day_of_year < elija_start.dayOfYear()){ zaman = "toba"; week = weeks[Math.floor((day_of_year - toba_start.dayOfYear())/7) ]}
    else if (day_of_year >= elija_start.dayOfYear() && day_of_year < mosa_start.dayOfYear()){ zaman = "elija"; week = weeks[Math.floor((day_of_year - elija_start.dayOfYear())/7) ]}
    else if (day_of_year >= mosa_start.dayOfYear() && day_of_year < takdes_start.dayOfYear()){ zaman = "mosa"; week = weeks[Math.floor((day_of_year - mosa_start.dayOfYear())/7) ]}
    else if (day_of_year >= takdes_start.dayOfYear() && day_of_year < beshara_start.dayOfYear()){ zaman = "takdes"; week = weeks[Math.floor((day_of_year - takdes_start.dayOfYear())/7) ]}
    else if (day_of_year >= beshara_start.dayOfYear() && day_of_year < melad_start.dayOfYear()){ zaman = "beshara"; week = weeks[Math.floor((day_of_year - beshara_start.dayOfYear())/7) ]}
    else if (day_of_year >= melad_start.dayOfYear() || day_of_year <= deneh_start.dayOfYear()){
        return (`${momobj.format("D-M")}/all/melad`)
    }

    return `${day}/${week}/${zaman}`
}