import { useTranslation, Trans } from 'react-i18next';
import {ArabicRegex, howArabic, EnglishRegex} from "../ArabicRegex";
import ParentDirectory from './ParentDirectory.js';
import {pagenum_string_setter, pagenum_string_getter} from "../utils.js"
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";

function MatchedTitles({ pagenum, setPagenum, data, searchText, setLangAppState}) {
    const { t, i18n } = useTranslation();

    let page = <></>
    let matched = []
    let counter = 0
    const searching_in_arabic = (howArabic(searchText) === 1)
    const searching_in_english = (howArabic(searchText) === 0)
    const lang_is_arabic = i18n.resolvedLanguage==="ar"


    let name;
    let regex_string;
    let regexObj;
    if (lang_is_arabic){
        name = "arabicName"
        regex_string = ArabicRegex(searchText)
        regexObj =  new RegExp(regex_string)
    } else {
        name = "name"
        regex_string = EnglishRegex(searchText)
        regexObj =  new RegExp(regex_string, "i")
    }

    function handleOnClick_match(entry, event){
        event.preventDefault()

        const start = entry.range[0]
         for(let i = 1; i < data.pages.length; i++){
                 if (start >= data.pages[i][0] && start <= data.pages[i][1]){
                    const new_page = i.toString()
                    const pagenum_setted = pagenum_string_setter(pagenum, "pagenum", new_page)
                    const scrollID_setted = pagenum_string_setter(pagenum_setted, "scrollID", entry[name])
                    const prev_clickcount = pagenum_string_getter(pagenum, "clickcount")
                    const new_clickcount = (Number(prev_clickcount) + 1).toString()
                    const clickcounter_setted = pagenum_string_setter(scrollID_setted, "clickcount", new_clickcount)
                    setPagenum(clickcounter_setted)
                    return;
                 }
         }

    } 


    for (let i = 0; i < data.toc.length; i++ ) {
        const text = " " + data.toc[i][name] + " "
        if (regexObj.test(text)){
            counter += 1
            matched.push(<div 
                className='my-4 mx-4 py-4 px-3 rounded-md border shadow-md cursor-pointer'
                onClick={(event) => handleOnClick_match(data.toc[i], event)}>
                <ParentDirectory data={data} toc_index={i} match_regex={regex_string}></ParentDirectory>
                    </div>)
        }
    }
    page = matched
        
    // else {
    //     page = <div>{t("bilingual")}</div>
    // }

    return (

    <div className="mt-6 mb-0 border-2 shadow-md rounded-sm bg-mysearchcardbg">
    <div className='text-myblue text-lg ms-3 mt-2 mb-3 '>
    { counter?
        <span className='font-bold'>{t("pre_search_text_titles")}</span>
        : <span className='text-red-800'>{t("no_results_titles")}</span>

    }
        "<span className='bg-yellow-200'>{searchText}</span>"
        <div className="text-sm">{t("results")}{counter}</div>
        <div className='text-sm mt-2'>
                {counter? <><IconContext.Provider value={{className: "inline global-class-name align-center text-sm" }}>
                    <IoIosInformationCircleOutline /> 
                </IconContext.Provider>
                <span className="ms-1">{t("titles_results_info")}</span></>: <></>}
        </div>

    </div>
    { searching_in_english&&lang_is_arabic?
            <div className='ms-3' > يبدو أن بحثك يحتوى على كلمات انجليزية، اذا كنت تريد البحث في النسخة الانجليزية <span className='clickable text-myblue' onClick={() => setLangAppState("en")}>اضغط هنا</span></div>
            : <></>
        }
        { searching_in_arabic&&!lang_is_arabic?
            <div className='ms-3' > It seems that your search contains Arabic words, if you want to search in the Arabic version <span className='clickable text-myblue' onClick={() => setLangAppState("ar")}> click here </span></div>
            : <></>
        }

    <div className='mt-6'>
        {page}
    </div>
    </div>
  )
}

export default MatchedTitles