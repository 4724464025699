import Parent from "./Parent.js"
import TocDepthAdjuster from "./TocDepthAdjuster.js"
import Options from "./Options.js"
import {pagenum_string_setter, pagenum_string_getter} from "../utils.js"
import { useTranslation, Trans } from 'react-i18next';

function TOC({pagenum, setPagenum, data, tocDepth, setTocDepth}) {
    const { t, i18n } = useTranslation();

    function handleOnClick(entry, event){
        // event.preventDefault()

        let name = "name"
        if (i18n.resolvedLanguage==="ar"){
           name = "arabicName"
        }

        // hena hanset el pnum h7seb el page num mn el data wel entry
        const start = entry.range[0]
        for(let i = 1; i < data.pages.length; i++){
                if (start >= data.pages[i][0] && start <= data.pages[i][1]){
                    const pagenum_setted = pagenum_string_setter(pagenum, "pagenum", i.toString())
                    const scrollID_setted = pagenum_string_setter(pagenum_setted, "scrollID", entry[name])
                    const clickcounter_setted = pagenum_string_setter(scrollID_setted, "clickcount", "0")
                    setPagenum(clickcounter_setted)
                    break;
                }
        }
    } 

    let content = []
    let parents = [];

    for (let k = 0; k < data.toc.length; k++){
        let indentation_level;
        const current_element_level = data.toc[k].absolute_level 
        const last_element_level = k? data.toc[k-1].absolute_level: 1
        // all the logic is based on the fact of "msh hatla3 ella ama anzel"
        if (current_element_level > last_element_level){
            // nzelt lgowa: el parent level howwa el last_element level
            parents.push(last_element_level) // add parent to parents
        } else if (current_element_level < last_element_level) {
            // tle3na tany. hageb akbar rakam as8ar mn el current level 
            // aked mawgod fel parents (la msh aked msln lw howwa el awal mn no3o fel goz2)
            let biggest_of_smaller;
            for (let m=parents.length-1; m >= 0; m-- ){
                if (parents[m] < current_element_level){
                    biggest_of_smaller = parents[m]
                    break;
                }
            }
            const index = (biggest_of_smaller == 1 )? 0 : parents.indexOf(biggest_of_smaller) 
            parents.splice(index+1)
          
        } 

        if (current_element_level> tocDepth) continue
        content.push(   
            <Parent
                parent={data.toc[k]} 
                onClick={(event) => handleOnClick(data.toc[k], event)} 
                toc={true}
                indentation_level={parents.length}
                />
        )
    }

    return (
        <div>
            <Options>
                <TocDepthAdjuster tocDepth={tocDepth} setTocDepth={setTocDepth}/>
            </Options>
            <div className="">
                {content}
            </div>
        </div>
    )
}

export default TOC