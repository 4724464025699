import * as fflate from 'fflate';
import { useTranslation} from 'react-i18next';

import Header from './components/Header'
import SearchPage from './components/SearchPage';
import ReadPage from './components/ReadPage';
import TopicsPage from './components/TopicsPage';
import MetaInfo from './components/MetaInfo';

async function decompress_json(path) {
  const compressed = new Uint8Array(
    await fetch(path).then(res => res.arrayBuffer())
  );
  const decompressed = fflate.decompressSync(compressed);
  return(decompressed);
}

async function string_decompressed (path){
  let result = await decompress_json(path)
  let utf8decoder = new TextDecoder(); // default 'utf-8' or 'utf8'
  let string = utf8decoder.decode(result)
  return(string)
}

async function parse_data(){
  let arabic;
  let arabic_string = await string_decompressed("/data/arabic-ccc.json.gz")
  arabic = await JSON.parse(arabic_string)

  let english
  let english_string = await string_decompressed("/data/english-ccc.json.gz")
  english = await JSON.parse(english_string)

  let arabic_index
  let arabic_index_string = await string_decompressed("/data/arabic_index.json.gz")
  arabic_index = await JSON.parse(arabic_index_string)

  let english_index
  let english_index_string = await string_decompressed("/data/english_index.json.gz")
  english_index = await JSON.parse(english_index_string)

  let toc
  let toc_string = await string_decompressed("/data/toc-ccc.json.gz")
  toc = await JSON.parse(toc_string)

  let pages
  let pages_string = await string_decompressed("/data/pages-ccc.json.gz")
  pages = await JSON.parse(pages_string)

  return ({
    arabic: arabic,
    english: english,
    arabic_index: arabic_index,
    english_index: english_index,
    toc: toc,
    pages: pages,
   })
}

let data = await parse_data()

function Catechism({langAppState, setLangAppState, topicView, setTopicView, view, setView, pagenum, setPagenum, tocDepth, setTocDepth, searchIn, setSearchIn, translateAll, setTranslateAll, searchText, setSearchText}) {
  const { t, i18n } = useTranslation();

//   useEffect(() => {
//     // remove the loader on mount
//     const element = document.getElementById("loader");
//     if (element) element.remove()
//   }, []);
  

  // those are app level state

  // Done moshkelet el init, de me7taga language detector from query string (de hat7el el linkat el english)
  // fe kol el app msh batchange el language mn i18n dyman b8ayar el langApppState w el effect da by-sync


  // useEffect(() => {
  //   if (searchParams.get("lng") === null) {
  //     searchParams.set("lng", "ar");
  //     setSearchParams(searchParams);
  //   }
  // }, [searchParams, setSearchParams]);

  // useEffect(() => {
  //   i18n.changeLanguage(langAppState)
  // }, [langAppState])

  // hna ba3ml el 7agat elly me7tag tet8ayar m3 el lang
  // i18n.on('languageChanged', function(lng) {
  //  const first_letter = topicView[0]
  //  if (lng === "ar"){
  //    if (howArabic(first_letter)===0) setTopicView("أ/") // else seb el topic view zy mahowa
  //  } else {
  //    if (howArabic(first_letter)===1) setTopicView("a/") // else seb el topic view zy mahowa
  //  }
  // })




   // hena ba8ayar le read lamma el pagenum yt8ayyar 3shan linkat el 3anawen, lazem a8ayar fe onClick state wa7da bs

  return (
    <div className="App whitespace-normals arabic">
      <div dir={i18n.resolvedLanguage === "ar"? "rtl": "ltr"}
       className="container mx-auto bg-background max-w-prose">
        <Header view={view} setView={setView} searchText={searchText} setSearchText={setSearchText}/>
        <div className="min-h-64 pb-8 border-b border-mycomplementary">
            {view === "search" && <SearchPage data={data} translateAll={translateAll} setTranslateAll={setTranslateAll} pagenum={pagenum} setPagenum={setPagenum} topicView={topicView} setTopicView={setTopicView} searchText={searchText} setSearchText={setSearchText} searchIn={searchIn} setSearchIn={setSearchIn} view={view} setView={setView} langAppState={langAppState} setLangAppState={setLangAppState}/>}
            {view === "topics" && <TopicsPage data={data} topicView={topicView} setTopicView={setTopicView} translateAll={translateAll} setTranslateAll={setTranslateAll} langAppState={langAppState}/>}
            {view === "read" && <ReadPage data={data} translateAll={translateAll} setTranslateAll={setTranslateAll} pagenum={pagenum} setPagenum={setPagenum} tocDepth={tocDepth} setTocDepth={setTocDepth}/>}
            {view === "meta_info" && <MetaInfo/>}
        </div>
      </div>
    </div>
  );
}

export default Catechism;
