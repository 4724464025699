import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowUp} from "react-icons/md";
import { useTranslation, Trans } from 'react-i18next';
import {pagenum_string_setter, pagenum_string_getter} from "../utils.js"


function Navigation({pagenum, setPagenum}) {
    const { t, i18n } = useTranslation();

    const current_pagenum = pagenum_string_getter(pagenum, "pagenum")

    function handleNext(){
        if(Number(current_pagenum) <= 82){
            setPagenum(pagenum_string_setter(pagenum, "pagenum", (Number(current_pagenum) + 1).toString() ))
        }
    }
    function handlePrev(){
        if (Number(current_pagenum) !== 0) { 
            setPagenum(pagenum_string_setter(pagenum, "pagenum", (Number(current_pagenum) - 1).toString() ))
        }
    }
    function handleTOC(){
        setPagenum("0@top@0")
    }
let classname_continer = "flex flex-row-reverse justify-end my-3 "
let classname_button = "clickable me-7 flex items-center "

  return (
    <div className="flex justify-between">
    <div className={classname_continer}>
        <button className={classname_button + ((Number(current_pagenum) === 83)? " text-mygreyedout " : "text-primary ")} onClick={handleNext}>
             <span>{t("next")}</span>
             { i18n.resolvedLanguage==="en"? <MdKeyboardArrowRight />: <MdKeyboardArrowLeft />}
        </button>
        <button className={classname_button + ((Number(current_pagenum) === 0)? " no-underline text-mygreyedout " : "text-primary ")} onClick={handleTOC}>
        <MdKeyboardArrowUp /> <span>{t("toc")}</span>
        </button>
        <button className={classname_button + ((Number(current_pagenum) === 0)? " no-underline text-mygreyedout " : "text-primary ")} onClick={handlePrev}>
            { i18n.resolvedLanguage==="ar"? <MdKeyboardArrowRight />: <MdKeyboardArrowLeft />}
            <span>{t("previous")}</span>
        </button>
    </div>
    </div>
  )
}

export default Navigation