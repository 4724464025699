import Topic from './Topic';
import { useTranslation, Trans } from 'react-i18next';
import { howArabic } from '../ArabicRegex.js';
import {useEffect} from "react";

function RenderLetterTopics({data, topicView, setTopicView, langAppState}) {
  const { t, i18n } = useTranslation();

  let index;
  let indices
  let letter = topicView.slice(0,topicView.indexOf("/"))
  console.log(letter)
  console.log(langAppState)
 
  if (langAppState === "ar"){
    index = data.arabic_index
  } else {
    index = data.english_index
  }

  indices  = index[letter]

  let content = [];

 function construct_state(parents_indices){
   topicView = letter + "/"
   for (let i = 0; i < parents_indices.length; i++){
     topicView +=  parents_indices[i]+ "/"
   }
   return topicView
 }

  for (let i = 0; i <indices.length; i++){
    content.push(<div className='mt-6'><Topic onClick={ () => setTopicView(construct_state([i])) } topic_object={indices[i]} level={0} topicView={topicView}/></div>)

    if(indices[i].hasOwnProperty("children")){
      for(let j = 0; j< indices[i].children.length; j++){
        content.push(<div> <Topic onClick={ () => setTopicView(construct_state([ i, j ])) } topic_object={indices[i].children[j]} level={1} topicView={topicView}/></div>)
   
        if(indices[i].children[j].hasOwnProperty("children")){
          for(let k = 0; k< indices[i].children[j].children.length; k++){
            content.push(<div> <Topic onClick={ () => setTopicView(construct_state([ i, j, k ])) } topic_object={indices[i].children[j].children[k]} level={2} topicView={topicView} /></div> )
       
            if (indices[i].children[j].children[k].hasOwnProperty("children")){
              for(let l = 0; l < indices[i].children[j].children[k].children.length;  l++){
                 content.push(<div> <Topic onClick={ () => setTopicView(construct_state([ i, j, k, l ])) } topic_object={indices[i].children[j].children[k].children[l]} level={3} topicView={topicView} /></div> )
              }
            }
          }
        }  
      }
    }

  }
  return (
    <div>{content}</div>
  )
}

export default RenderLetterTopics