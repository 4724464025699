import { useState } from 'react';
import { LuArrowDownWideNarrow } from "react-icons/lu";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import { useTranslation, Trans } from 'react-i18next';

function TranslateAllCheckbox({translateAll, setTranslateAll}) {
  const { t, i18n } = useTranslation();

  const handleChange = () => {
    setTranslateAll(((translateAll === "true")? "false": "true"))
  };

  return (
              <label className='inline-flex justify-start justify-items-start items-center content-center'>

              <span>
              <IconContext.Provider value={{className: "inline global-class-name align-center text-sm" }}>
            <IoIosInformationCircleOutline /> 
              </IconContext.Provider>

                <span className="text-sm"> {t('translate_all_checkbox.part1')}</span> 
                <span>&nbsp;</span>
                <span className='text-primary text-sm font-bold cursor-pointer'>{t('translate_all_checkbox.part2')} </span>
              
              {/* <span className='align-baseline inline-flex items-baseline content-baseline cursor-pointer'> */}
              <IconContext.Provider value={{className: "inline global-class-name text-sm" }}>
                <LuArrowDownWideNarrow />
              </IconContext.Provider>
              <span>&nbsp;</span>
              
              <input
              type="checkbox"
              checked={(translateAll === "true")}
              onChange={handleChange}
              className='rounded size-4 cursor-pointer align-middle accent-mycomplementary'
            /> 
            </span>

            {/* </span> */}
            </label>
  )
}

export default TranslateAllCheckbox