import { useTranslation, Trans } from 'react-i18next';
import {Link } from "react-router-dom";

function PageButtons({view, setView}){
    const { t, i18n } = useTranslation();

    const selectedClassname = "cursor-pointer text-center bg-mybuttonselected text-white border-b-4 border-b-mycomplementary px-2 pt-1 pb-1 shadow-sm"
    const notSelectedClassName = "border-b-2 cursor-pointer text-center bg-mybuttonbg px-2 pt-1 pb-1  shadow-sm"
    
    let ReadButtonClassname, topicsButtonClassname, searchButtonClassname;
    
    if (view === "read"){
        ReadButtonClassname = selectedClassname + " rounded-ts-md "
        topicsButtonClassname = notSelectedClassName + " border-x border-x-background "
        searchButtonClassname = notSelectedClassName + " rounded-te-md "
    } else if (view === "topics"){
        ReadButtonClassname = notSelectedClassName + " border-e border-e-background  rounded-ts-md "
        topicsButtonClassname = selectedClassname 
        searchButtonClassname = notSelectedClassName +  " border-s border--background rounded-te-md "

    } else if (view === "search"){
        ReadButtonClassname = notSelectedClassName + " rounded-ts-md "
        topicsButtonClassname = notSelectedClassName + " border-x border-x-background "
        searchButtonClassname = selectedClassname + " rounded-te-md "
    } else {
        ReadButtonClassname = notSelectedClassName + " rounded-ts-md "
        topicsButtonClassname = notSelectedClassName +" border-x border-x-background "
        searchButtonClassname = notSelectedClassName +  " border-s border--background rounded-te-md  "
    }

    function handleSearchClick(){
        if (view !== "search"){
            setView("search")
        }
    }
    function handleTopicsClick(){
        if (view !== "topics"){
            setView("topics")
        }
    }
    function handleReadClick(){
        if (view !== "read"){
            setView("read")
        }
    }
    return(
        <div className="flex w-full">
            <div className="w-full border-b-2 "></div>
            <div className="flex content-evenly"> 

                <div className={ReadButtonClassname + "w-16"} onClick={handleReadClick}>
                {t("read")}
                </div>

                <div className={topicsButtonClassname + "min-w-16"} onClick={handleTopicsClick}>
                    {t("topics")} 
                </div>
        
                <div className={searchButtonClassname + "w-16"} onClick={handleSearchClick}>
                {t("search")} 
                </div>
                
            </div>
            <div className="w-full border-b-2"></div>
        </div>
    )   
}

export default PageButtons
