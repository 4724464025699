import React from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowUp} from "react-icons/md";
import { useTranslation, Trans } from 'react-i18next';

function BackToBooks({setBook}) {
    const { t, i18n } = useTranslation();

    function handle_gotoindex() {
        setBook("chooser")
    }

  return (
    <button className="clickable me-7 mt-4 flex items-center text-primary " onClick={handle_gotoindex}>
    { i18n.resolvedLanguage==="ar"? <MdKeyboardArrowRight />: <MdKeyboardArrowLeft />}
    <span>{t("back-to-books")}</span>
    </button>

  )
}

export default BackToBooks