import Paragraph from "./Paragraph";
import TranslateAllCheckbox from "./TranslateAllCheckbox";
import { useTranslation, Trans } from 'react-i18next';
import {ArabicRegex, howArabic, EnglishRegex} from "../ArabicRegex";

function MatchedParagraphs({translateAll, setTranslateAll, data, searchText, setLangAppState}) {
    const { t, i18n } = useTranslation();

    let page = <></>
    let matched = []
    let counter = 0
    const lang_is_arabic = i18n.resolvedLanguage==="ar"
    const searching_in_arabic = (howArabic(searchText) === 1)
    const searching_in_english = (howArabic(searchText) === 0)

    let regex_string, regexObj, paragraphs_data;
    if (lang_is_arabic){
        regex_string = ArabicRegex(searchText)
        regexObj =  new RegExp(regex_string)
        paragraphs_data = data.arabic
    } else {
        regex_string = EnglishRegex(searchText)
        regexObj =  new RegExp(regex_string, "i")
        paragraphs_data = data.english
    }

    for (let i = 1; i <= 2685; i++ ) {
        let text;
        if (lang_is_arabic){
            text = " " + data.arabic[i].data + " "
        } else {
            text = " " + data.english[i].data.text + " "
        }
        if (regexObj.test(text)){
            counter += 1;
            matched.push(<div className='mb-4 mx-4 bg-background'>
                    <Paragraph translateAll={translateAll} pnum={i} data={data} card="both" match_regex={regex_string}></Paragraph>
                    </div>)
        }
    }
    page = matched
    // } else if (howArabic(searchText) === 0){
    //     matched.push(<div>searching for english</div>)
    //     for (let i = 1; i <= 2685; i++ ) {
    //         if (data.english[i].data.text.includes(searchText)){
    //             matched.push(<Paragraph translateAll={i18n.resolvedLanguage == "en"? translateAll: "true"} pnum={i} data={data} card="both" match_regex={searchText}></Paragraph>)
    //         }
    //     }
    //     page = matched
    // } else {
    //     page = <div>{t("bilingual")}</div>
    // }
    
  return (
    
    <div className="mt-6 mb-0 border-2 shadow-md rounded-sm bg-mysearchcardbg">
    <div className='text-myblue text-lg mx-3 mt-2 mb-3 '>
    { counter?
        <span className='font-bold'>{t("pre_search_text_paragraphs")}</span>
        : <span className='text-red-800'>{t("no_results_paragraphs")}</span>

    }
        "<span className='bg-yellow-200'>{searchText}</span>"
        <div className="text-sm">{t("results")} {counter}</div>
        { counter? <div><TranslateAllCheckbox 
                translateAll={translateAll} 
                setTranslateAll={setTranslateAll}/></div>
            : <></>}

    </div>
    { searching_in_english&&lang_is_arabic?
            <div className='ms-3' > يبدو أن بحثك يحتوى على كلمات انجليزية، اذا كنت تريد البحث في النسخة الانجليزية <span className='clickable text-myblue' onClick={() => setLangAppState("en")}>اضغط هنا</span></div>
            : <></>
        }
        { searching_in_arabic&&!lang_is_arabic?
            <div className='ms-3' > It seems that your search contains Arabic words, if you want to search in the Arabic version <span className='clickable text-myblue' onClick={() => setLangAppState("ar")}> click here </span></div>
            : <></>
        }

    <div className="mt-6">
        {page}
    </div>
    </div>
  )
}

export default MatchedParagraphs