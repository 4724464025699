import { HiMagnifyingGlass } from "react-icons/hi2";
import { useTranslation, Trans } from 'react-i18next';
import { useState, useEffect } from 'react';

function SearchBar({ searchText, setSearchText, view, setView}){
    const { t, i18n } = useTranslation();
    const [LocalSearchState, setLocalSearch] = useState("")

    useEffect(() => setLocalSearch(searchText), [searchText])

    // tabe3y
    function handleSubmit(e){
        e.preventDefault();
        setSearchText(LocalSearchState)
    }

    // 3han el user may7tagsh ydos enter lamma yemsa7 aw lamma yktb "?"
    function handleChange(e){
        setLocalSearch(e.target.value)
        console.log(e.target.value)
        if (e.target.value === ""){
            setSearchText("")
        } else if(e.target.value === "?") {
            setSearchText("?")
        } else if(e.target.value === "؟") {
            setSearchText("؟")
        }
    }
    return (
        <form className="flex flex-row content-center items-stretch"
            onSubmit={handleSubmit}
        >
            <input
            name = "searchBar"
            value = {LocalSearchState}
            onChange={handleChange}
            className="w-55 pt-2 pb-1 px-1 align-middle table-cell border rounded-sm shadow-sm"
            type="text"
            placeholder={t("search")
            
            }/>
            <button
            type="submit"
            className="border border-s-0 shadow-sm rounded-sm bg-mysearchcardbg px-3 table-cell align-middle">
                <HiMagnifyingGlass />
            </button>
        </form>
    )
}

export default SearchBar