import Indented from './Indented'
import { useTranslation, Trans } from 'react-i18next';

function Topic({onClick=() => (1+1), topic_object, level, with_numbers=true, with_refs=true, topicView}) {
    const { t, i18n } = useTranslation();

    let name = "name"
  
    let text_lg = " "
    if(((topicView.match(/\//g) || []).length !== 1)){
        text_lg = " text-lg "
    } 

    let paragraphs = []
    let refs = []

    if (topic_object.hasOwnProperty("paragraphs")) {
        for (let i = 0; i< topic_object.paragraphs.length; i++){
            let pnum = topic_object.paragraphs[i]
            paragraphs.push(<span className='text-sm text-primary clickable '>
                <span key={pnum} >{pnum}</span>
                {((i===topic_object.paragraphs.length-1)? <></>: <span className='text-black'>, </span>)}
                </span>)
        }
    }

    if (topic_object.hasOwnProperty("refs")) {
        refs.push(
            <span className='font-normal text-sm'>{t("see_also")} </span>
        )
        for (let i = 0; i< topic_object.refs.length; i++){
            let ref = topic_object.refs[i]
            refs.push(
                <span key={ref} className='font-normal text-sm'>{ref}
                {((i===topic_object.refs.length-1)? <></>: <span>{t("ref_join")}</span>)}
                </span>
                )
        }
    }

    let className = " whitespace-pre-wrap "
    switch (level) {
        case 0:
            className += " font-bold "
            if(((topicView.match(/\//g) || []).length === 1)) className += " mt-4 "
            break;
        case 1:
            className +=' text-myblue '
            break;
        case 2:
            className +=' text-myred '
            break;
        case 3:
            className +=" text-mygreen "
            break;
    }

    const clickable = (topic_object.hasOwnProperty("children") && topic_object.children.length !== 0 ) || (topic_object.hasOwnProperty("paragraphs") && topic_object.paragraphs.length !== 0 )
    return (
        <Indented onClick={clickable? onClick: () => (1+1)} indentation_level={((topicView.match(/\//g) || []).length === 1)? level: 0} className=" border-s-2 ps-6 ">
            <span className={className + (clickable? " clickable ": "")+ text_lg}>{topic_object[name]} {'\u200F'}</span>
            {with_numbers? <><span>{" "}</span>{paragraphs}</>: []}
            {with_refs? <><span>{" "}</span>{refs}</>: []}
        </Indented>
    )
}

export default Topic