import React from 'react'
import { useTranslation, Trans } from 'react-i18next';

function ChapterPicker({bible_data, book, setBook}) {
    const { t, i18n } = useTranslation();
    let bookname = book.split(".")[0]
    let current_chapter = book.split(".")[1]

    let book_chapter_len  = bible_data.arabic[bookname].length

    let handle_click = (num) => {
         setBook(bookname  + "." + num)
     }

     const className = "shadow-md p-2 me-0.5 mb-0.5 text-primary border "
     const selectedClassName = "shadow-md p-2 me-0.5 mb-0.5 bg-mybuttonselected text-white border"

    let chapter_buttons = []

    for (let i = 1; i <= book_chapter_len; i++) {
        chapter_buttons.push(
            <button className={i == current_chapter ? selectedClassName: className} onClick={() => handle_click(i)}>{i}</button>
        )
    }

  return (
    <>
        <div className='mt-3 flex flex-row flex-wrap justify-center'>
            {chapter_buttons}
        </div>
    </>
  )
}

// import { useTranslation, Trans } from 'react-i18next';
// import { address_getter } from '../utils';

// function LetterPicker({topicView, setTopicView, langAppState, info=true}) {
//     const { t, i18n } = useTranslation();

//     const Aletters = ["أ", "ب", "ت", "ث", "ج", "ح", "خ", "د", "ذ", "ر", "ز", "س", "ش", "ص", "ض", "ط", "ظ", "ع", "غ", "ف", "ق", "ك", "ل", "م", "ن", "ه", "و", "ي"]
//     const Eletters = ["A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "O", "P", "QR", "S", "T", "U", "V", "WXYZ" ]
    
//     let letters
//     let letter
//     let handle_click;
//     if (langAppState === "ar") {
//         letters = Aletters
//     } else {
//         letters = Eletters
//         info = false
        
//     }

//     letter = topicView.split("/")[0]
//     handle_click = (letter) => {
//         setTopicView(letter.toLowerCase() + "/")
//     }


//       const className = "shadow-md p-2 me-0.5 mb-0.5 text-primary border "
//       const selectedClassName = "shadow-md p-2 me-0.5 mb-0.5 bg-mybuttonselected text-white border"

//       let letter_elements = letters.map( (each_letter) => {
//         if (each_letter.toLowerCase() == letter) {
//             return <button className={selectedClassName} onClick={() => handle_click(each_letter)}>{each_letter}</button>
//         } else return (<button className={className} onClick={() => handle_click(each_letter)}>{each_letter}</button>)
//     })


//     return (
//         <>
//         <div className='mt-6 flex flex-row flex-wrap justify-center'>
//             {letter_elements}
//         </div>
//         {info? <div className="mt-5 mb-6 text-sm">  *الموضوعات في العربية مقسمة بحسب الحرف الاول من مصدر الكلمة، مثلا "ثقة" ستجدها تحت حرف الواو لأن مصدرها "وَثَقَ"  </div> : <></>}
//         </>
//     )
// }

// export default LetterPicker
export default ChapterPicker